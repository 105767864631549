import React from "react";

import AddressAndJurisdiction from "./components/AddressAndJurisdiction/AddressAndJurisdiction";
import PlanningAndZoning from "./components/PlanningAndZoning/PlanningAndZoning";
import Entitlement from "./components/Entitlement/Entitlement";
import Environmental from "./components/Environmental/Environmental";
import PermittingTimelines from "./components/PermittingTimelines/PermittingTimelines";
import SupplementarySourcesViewer from "./components/SupplementarySourcesViewer/SupplementarySourcesViewer";
import SavedSearches from "./components/SavedSearches/SavedSearches";
import ThreeColumnLayout from "../../layouts/ThreeColumnLayout";
import FixedSideNavPopover from "../../pages/addressSearch/components/FixedSideNavPopover/FixedSideNavPopover";
import SideNav from "../../components/ui/SideNav/SideNav";
import { AllSearchAddressInfos } from "./helpers/demoSectionInfo";
import TextSkeleton from "../../components/ui/Loaders/TextSkeleton";
import { twMerge } from "tailwind-merge";
import { useAppSelector } from "../../app/hooks";

export default function Demo() {
  const { isLoading, isFetched } = useAppSelector((state) => state.demo);

  const leftColRender = isLoading ? (
    TextSkeleton({ lines: 7, lineWidth: 70 })
  ) : (
    <SideNav className="sticky top-6" sections={AllSearchAddressInfos} />
  );

  if (!isFetched) return <></>;

  return (
    <ThreeColumnLayout
      leftCol={leftColRender}
      className={twMerge(
        "defaultPageStyle",
        (isLoading || isFetched) && "md:mt-[75px] lg:mt-[150px]",
      )}
    >
      <>
        <FixedSideNavPopover sections={AllSearchAddressInfos} />

        <AddressAndJurisdiction />
        <PlanningAndZoning />
        <Entitlement />
        <Environmental />
        <PermittingTimelines />
        <SupplementarySourcesViewer />
        <SavedSearches />
      </>
    </ThreeColumnLayout>
  );
}
