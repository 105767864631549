import { KeyValueContentType } from "../../../../../components/ui/KeyValueSection/KeyValueSection";
import { SectionType } from "../../../helpers/renderers";

export const zoningUseClassificationData: {
  ["Envelope"]: KeyValueContentType;
  ["Parking"]: KeyValueContentType;
} = {
  ["Envelope"]: [
    { ["Front Setbacks"]: "None" },
    { ["Side Setbacks"]: "Not less than 3.5 feet" },
    { ["Rear Setbacks"]: "None" },
    {
      ["Open Space Requirement"]:
        "Rear yards must maintain an open, permeable yard with an area of at least 15 times the lot width and a minimum of 450 square feet between the property line that faces the water and the front of any structure.",
    },
    {
      ["Height"]:
        "Generally limited to 30 feet for buildings with a flat roof and 35 feet for buildings with varied or stepped back rooflines. However, structures fronting on Walk Streets are limited to a maximum height of 28 feet.",
    },
  ],
  ["Parking"]: [
    {
      ["Parking Quantity"]:
        "For single-family dwellings on lots less than 40 feet in width, two parking spaces are required. For single-family dwellings on lots 40 feet or more in width, three parking spaces are required, with the third space potentially being uncovered and in tandem with the other two required covered spaces. For multiple dwellings and duplexes on lots less than 40 feet in width, two parking spaces per dwelling unit are required. For multiple dwellings and duplexes on lots 40 feet or more in width, two parking spaces per dwelling unit plus one guest parking space for every four units are required.",
    },
  ],
};

export const zoningInformationData: string[] = [
  "No zoning violations found on record.",
  "Prior permits include approval for a duplex onversion in 1980 and an ADU addition in 2021.",
];

export const specificPlanAreaData: SectionType = {
  title: "Applicable Specific Plan: Venice Coastal Zone Specific Plan",
  sections: [
    {
      title: "Designated Subarea:",
      paragraphs: [
        "Subarea Type: Residential Medium II.",
        "Purpose: To maintain a balance between preserving neighborhood character and accommodating moderate-density residential growth.",
      ],
    },
    {
      title: "Special Considerations:",
      paragraphs: [
        "Coastal Access Protection: Projects must not obstruct public access to coastal resources.",
        "View Corridor Preservation: Height and massing regulations are intended to preserve ocean and scenic views for surrounding properties.",
      ],
    },
  ],
};

export const generalPlanLandUseData: string =
  'The "Low Medium II Residential" overlay in Los Angeles outlines zoning regulations for specific residential areas. It aims to balance density with neighborhood character, allowing for moderate residential development. Key principles include maintaining a mix of housing types, such as duplexes and small apartment buildings, while preserving community aesthetics. The impact on building development includes restrictions on height and bulk, influencing architectural designs to fit within the neighborhood context. Real estate implications involve potential for increased property values due to desirable, controlled growth, while ensuring sustainable and cohesive urban planning.';

export const overlaysData: {
  link: { title: string; url: string };
  body: string;
}[] = [
  {
    link: {
      title: "Venice Community Plan",
      url: "https://planning.lacity.gov/plans-policies/community-plan-area/venice",
    },
    body: "The Venice Community Plan outlines a vision for the development and preservation of Venice, Los Angeles. It emphasizes sustainable growth, maintaining the unique character of the area, and enhancing public spaces. Key principles include promoting mixed-use development, preserving historic architecture, and improving transportation infrastructure. The plan aims to balance new construction with the conservation of Venice's eclectic style, impacting building development by encouraging designs that reflect the community's artistic heritage. Real estate strategies focus on affordable housing and preventing displacement, ensuring a diverse and inclusive neighborhood.",
  },
  {
    link: {
      title: "Venice Coastal Zone Specific Plan",
      url: "https://planning.lacity.gov/plans-policies/overlays/venice-coastal-zone",
    },
    body: "The Venice Coastal Zone Specific Plan is a regulatory framework guiding development in the Venice area of Los Angeles to preserve its unique coastal character. Key principles include maintaining public access to the beach, protecting natural resources, and ensuring new developments are compatible with the existing community. The plan impacts building development by imposing height restrictions, architectural guidelines, and density limits. It aims to balance growth with environmental preservation, influencing real estate values and promoting sustainable, community-focused urban planning.",
  },
  {
    link: {
      title: "Los Angeles Coastal Transportation Corridor Specific Plan",
      url: "https://planning.lacity.gov/plans-policies/overlays/coastal-transportation-corridor",
    },
    body: "The Los Angeles Coastal Transportation Corridor Specific Plan aims to manage transportation infrastructure and development within the coastal areas of Los Angeles. Key principles include improving traffic flow, enhancing public transit, and promoting sustainable transportation options. The plan impacts building development by imposing requirements for traffic mitigation measures and encouraging transit-oriented development. Architectural designs may need to incorporate features that support these goals, such as pedestrian-friendly elements. Real estate projects must consider these regulations to ensure compliance, potentially affecting project timelines and costs.",
  },
  {
    link: { title: "Venice Coastal Jurisdiction", url: "" },
    body: "The California Coastal Commission overlay in Los Angeles sets forth regulations to protect and manage the coastal resources. Key principles include preserving public access to the coast, protecting marine environments, and ensuring sustainable development. The document impacts building development by imposing strict guidelines on construction to minimize environmental damage, influencing architectural designs to be more eco-friendly, and affecting real estate by potentially limiting development opportunities in sensitive coastal areas. The intent is to balance growth with conservation, ensuring long-term ecological health and public enjoyment of the coastline.",
  },
];

export const easementsOrRightOfWays: SectionType = {
  title: "Recorded Easements:",
  sections: [
    {
      title: "Utility Easement (1980):",
      paragraphs: [
        "Location: Rear of the parcel.",
        "Purpose: Grants access to the local utility provider for maintenance of power and sewer lines.",
        "Impact: Limits development or construction in the designated easement area.",
      ],
    },

    {
      title: "Pedestrian Right-of-Way (1992):",
      paragraphs: [
        "Location: Along the western edge, adjacent to a public alley.",
        "Purpose: Allows public pedestrian access as part of the alley network.",
        "Impact: Restricts fencing or permanent structures that may block access.",
      ],
    },

    {
      title: "Drainage Easement (2008):",
      paragraphs: [
        "Location: Southeast corner of the parcel.",
        "Purpose: Ensures proper runoff management for adjacent properties and public stormwater systems.",
        "Impact: Requires unimpeded drainage flow; may limit landscaping or alterations in that area.",
      ],
    },

    {
      title: "Conclusion:",
      paragraphs: [
        "The parcel is subject to multiple easements affecting development and site use. These easements must be considered in any future building or landscaping plans to avoid conflicts and ensure compliance.",
      ],
    },
  ],
};
