import React, { useMemo } from "react";
import Accordion from "../../components/ui/Accordion/Accordion";
import ReportSection from "../../components/ui/ReportSection/ReportSection";
import KeyValueSection from "../../components/ui/KeyValueSection/KeyValueSection";
import { filterValidContent } from "../../helpers/checkValidObjValues";
import { UnfilteredKeyValueContentType } from "../../helpers/checkValidObjValues";
import PlanningAndZoningSkeleton from "./components/PlanningAndZoningSkeleton";
import { useAppSelector } from "../../app/hooks";
import { PlanningAndZoningInfo } from "../../helpers/constants";
import AccordionSectionSpacer from "../../components/ui/Accordion/AccordionSectionSpacer";

export default function PlanningAndZoning() {
  const {
    endpointOutputs: { siteInfoResponse },
    isLoading,
  } = useAppSelector((state) => state.addressSearch);

  const zoningContent: UnfilteredKeyValueContentType = useMemo(
    () =>
      siteInfoResponse
        ? [
            { zoning_description: siteInfoResponse?.zoning_description },
            { additional_zones: siteInfoResponse?.additional_zones },
          ]
        : [],
    [siteInfoResponse],
  );
  const filteredZoningContent = filterValidContent(zoningContent);

  if (isLoading) return <PlanningAndZoningSkeleton />;

  if (!siteInfoResponse && !isLoading) return <></>;

  return (
    <Accordion
      title={PlanningAndZoningInfo.title}
      id={PlanningAndZoningInfo.id}
    >
      <AccordionSectionSpacer>
        {/* Zoning */}
        {(!!siteInfoResponse?.zoning || isLoading) && (
          <ReportSection
            title="Zoning"
            collapsible={filteredZoningContent.length > 0}
            secondaryTitle={siteInfoResponse?.zoning}
          >
            {filteredZoningContent.length > 0 ? (
              <KeyValueSection content={filteredZoningContent} />
            ) : undefined}
          </ReportSection>
        )}

        {/* General Plan Land Use */}
        {(!!siteInfoResponse?.general_plan || isLoading) && (
          <ReportSection
            title="General Plan Land Use"
            secondaryTitle={siteInfoResponse?.general_plan}
          >
            {siteInfoResponse?.general_plan_description &&
            siteInfoResponse?.general_plan_description.length > 0 ? (
              <p className="body-font">
                {siteInfoResponse?.general_plan_description}
              </p>
            ) : undefined}
          </ReportSection>
        )}

        {/* Overlays */}
        {!!siteInfoResponse?.overlays && (
          <ReportSection title="Overlays" collapsible>
            {siteInfoResponse?.overlays?.map((overlay, i) => (
              <div key={overlay.fullname}>
                {overlay.url ? (
                  <a
                    href={overlay.url}
                    target={"_blank"}
                    className={"link mb-1.5 block w-fit"}
                  >
                    {overlay.fullname}
                  </a>
                ) : (
                  <p className="body-font mb-1.5"> {overlay.fullname}</p>
                )}

                <p className="desc-font">{overlay.description ?? ""}</p>

                {siteInfoResponse?.overlays?.length !== i + 1 && (
                  <hr className="line-break" />
                )}
              </div>
            ))}
          </ReportSection>
        )}
        {/* Ordinance */}
        {!!siteInfoResponse?.ordinance &&
          siteInfoResponse?.ordinance.map((ordinance) => (
            <ReportSection
              key={ordinance.name[0]}
              title={
                ordinance.name[0].toUpperCase() +
                ordinance.name.slice(1).toLowerCase() +
                " Ordinance"
              }
              secondaryTitle={ordinance.name}
              collapsible
            >
              <p className="body-font">{ordinance.description ?? ""}</p>
            </ReportSection>
          ))}
      </AccordionSectionSpacer>
    </Accordion>
  );
}
