import { PreviewCardType } from "../PreviewCard";
import {
  sb9HoverText,
  sb35HoverText,
  sb684HoverText,
  bonusDensityHoverText,
  ab2097HoverText,
  ab2011HoverText,
} from "../../../../../regiondata/losangeles";

export const listColData: {
  state: PreviewCardType[];
  assembly: PreviewCardType[];
} = {
  state: [
    {
      title: "State Bill 9",
      url: "https://app.redflagdata.com/content/California-2021-SB9-Chaptered.pdf",
      description: sb9HoverText,
    },
    {
      title: "State Bill 423",
      url: undefined,
      description: sb35HoverText,
    },
    {
      title: "State Bill 684",
      url: "https://app.redflagdata.com/content/SB684.pdf",
      description: sb684HoverText,
    },
  ],
  assembly: [
    {
      title: "Assembly Bill Bonus Density",
      url: undefined,
      description: bonusDensityHoverText,
    },
    {
      title: "Assembly Bill 2097",
      url: "https://app.redflagdata.com/content/ab2097.pdf",
      description: ab2097HoverText,
    },
    {
      title: "Assembly Bill 684",
      url: undefined,
      description: ab2011HoverText,
    },
  ],
};

export const listItems = ["Assembly Bill", "State Bill"];

export const cityCouncilMinutesData: PreviewCardType[] = [
  {
    title:
      "Van Nuys Boulevard / San Fernando Road / Bradley Avenue / Laurel Canyon Boulevard / Jobs and Economic Development Incentive (JEDI) Zone",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=20-0304",
    description:
      "ECONOMIC DEVELOPMENT AND JOBS COMMITTEE REPORT relative to the establishment of a Jobs and Economic Development Incentive (JEDI) Zone for a portion of the Van Nuys Boulevard Corridor between Bradley Avenue to the northeast and Laurel Canyon Boulevard to the southwest in Council District Seven.",
  },
  {
    title:
      "6th Street / Harbor Boulevard / Pacific Avenue / Avalon Boulevard / C Street / Opp Street / Wilmington Avenue / 103rd Street / 114th Street / San Pedro / Wilmington / Watts / Jobs and Economic Development Incentive (JEDI) Zone",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=20-0345",
    description:
      "ECONOMIC DEVELOPMENT AND JOBS COMMITTEE REPORT relative to establishment of Jobs and Economic Development Incentive (JEDI) Zones at the Watts Site location on Wilmington Avenue between 103rd Street on the north and 114th Street on the south; and the Wilmington Site, on Avalon Boulevard between C Street and Opp Street.",
  },
  {
    title:
      "Hollywood Boulevard / Las Palmas Avenue / Ivar Avenue / Jobs and Economic Development Incentive (JEDI) Zone Program",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=20-0382",
    description:
      "ECONOMIC DEVELOPMENT AND JOBS COMMITTEE REPORT relative to the establishment of a Jobs and Economic Development Inventive (JEDI) Zone for a portion of Hollywood Boulevard beginning at Las Palmas Avenue, continuing along Hollywood Boulevard business corridor, and ending at Ivar Avenue in Council District 13.",
  },
  {
    title:
      "Gault Street / Vanowen Street / Reseda Boulevard / Etiwanda Avenue / Jobs and Economic Development Incentive (JEDI) Zone",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=22-0101",
    description:
      "ECONOMIC DEVELOPMENT AND JOBS COMMITTEE REPORT relative to the feasibility of establishing a Jobs and Economic Development Incentive (JEDI) Zone for the area bounded by Gault Street to the north, Vanowen Street to the south, Reseda Boulevard on the west, and Etiwanda Avenue to the east.",
  },
  {
    title:
      "Jobs and Economic Development Incentive (JEDI) Zone Program / Sherman Way Corridor / Reseda / Canoga Park",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=20-0308",
    description:
      "ECONOMIC DEVELOPMENT AND JOBS COMMITTEE REPORT relative to the establishment of the Canoga Park Sherman Way Jobs and Economic Development Incentive (JEDI) Zone.",
  },
  {
    title:
      "Official Police Garages (OPG) / Storage of Impounded Vehicles / Overflow Lots",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=17-1405",
    description:
      "PLANNING AND LAND USE MANAGEMENT COMMITTEE REPORT relative to Zoning Code regulations that apply to the storage of impounded vehicles and Official Police Garages (OPGs).",
  },
  {
    title:
      "Impacts of S. 144 (Casey) / Promise Zone - Central Los Angeles and South Los Angeles",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=17-0265",
    description:
      "ECONOMIC DEVELOPMENT COMMITTEE REPORT relative to the impacts of Federal legislation (S. 144, Casey) on the Central Los Angeles and South Los Angeles Promise Zones.",
  },
  {
    title:
      "Canoga Avenue / Cohasset Street / Sherman Way / Metro Orange Line Right of Way / Zone Change Options / Restricted Industrial (MR-1) Zone",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=14-1719",
    description:
      "PLANNING AND LAND USE MANAGEMENT COMMITTEE REPORT relative to possible zone change options for the area within the boundaries of the west side of Canoga Avenue.",
  },
  {
    title: "23577 West Calabasas Road / General Plan Amendment and Zone Change",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=16-1090",
    description:
      "PLANNING AND LAND USE MANAGEMENT COMMITTEE REPORT relative to a General Plan Amendment and Zone Change Ordinance for the property located at 23577 West Calabasas Road.",
  },
  {
    title:
      "1661 West 37th Place / General Plan Amendment / Zone Change / Height District",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=16-1474",
    description:
      "MOTION (HARRIS-DAWSON - ENGLANDER) and RESOLUTION relative to amending prior Council action of February 22, 2017 to denote Low Medium I Residential as the correct land use category for property located at 1661 West 37th Place.",
  },
  {
    title: "SLATE-Z Promise Zone Designation",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=16-0191",
    description:
      "RESOLUTION (PRICE - WESSON, ET.AL) relative to the City's position in connection with proposed SLATE-Z Promise Zone designation.",
  },
  {
    title:
      "2268 West Pico Boulevard / General Plan Amendment / General Commercial / Zone and Height District Change / South Los Angeles Community Plan",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=17-0727",
    description:
      "PLANNING AND LAND USE MANAGEMENT (PLUM) COMMITTEE REPORT relative to the initiation of a General Plan Amendment (GPA), amendment to the South Los Angeles Community Plan (SLACP) land use map, and preparation of a draft Zone and Height District Change Ordinance for the property located at 2268 West Pico Boulevard.",
  },
  {
    title:
      "AB 1505 (Bloom) / Inclusionary Housing/Zoning Requirements / Affordable to Low or Moderate Income Households",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=17-0002-S75",
    description:
      "COMMUNICATION FROM THE CHIEF LEGISLATIVE ANALYST and RESOLUTION (BONIN - HARRIS-DAWSON) relative to including in the City's 2017-18 State Legislative Program, support for AB 1505 (Bloom) which would authorize the legislative body of any city, county, or city and county to adopt ordinances to establish, as a condition of development, inclusionary housing/zoning requirements.",
  },
  {
    title: "Elements of Economics Development Program / Business Incentives",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=13-0934-S2",
    description:
      "ECONOMIC DEVELOPMENT AND JOBS COMMITTEE REPORT relative to changes to Jobs and Economic Development Incentive (JEDI) Zone Establishment Policy.",
  },
  {
    title:
      "1775 North lvar Avenue / Josephs Cafe / April 24, 2018 / Central Los Angeles Area Planning Commission / City Charter Section 245",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=18-0416",
    description:
      "MOTION (O'FARRELL - KORETZ) relative to asserting jurisdiction over the April 24, 2018 action of the Central Los Angeles Area Planning Commission to address violations by Joseph's Café.",
  },
  {
    title:
      "5001 North Balboa Boulevard / Armenian Cultural Foundation / Old Fire Station No. 83/ General Plan Amendment / Zone Change / Initiate Proceedings",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=13-0476-S1",
    description:
      "PLANNING AND LAND USE MANAGEMENT COMMITTEE REPORT relative to the Department of City Planning (DCP) to initiate proceedings for a General Plan Amendment and Zone Change for property located at 5001 Balboa Boulevard.",
  },
  {
    title:
      "Studio City Residential Floor Area (RFA) / Supplemental Use District",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=08-2332",
    description:
      "NEGATIVE DECLARATION, ADDENDUM, COMMUNICATIONS FROM THE LOS ANGELES CITY PLANNING COMMISSION and CITY ATTORNEY, and ORDINANCE FIRST CONSIDERATION relative to repealing the Studio City Residential Floor Area Supplemental Use District and effectuating a Zone Change for those parcels in the non-hillside singlefamily residential lots in Studio City.",
  },
  {
    title:
      "Chatsworth-Northridge Industrial Core / Innov818 / Transportation Shuttle / Metro Local Return Funds / Funding Source",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=17-0938-S3",
    description:
      "TRANSPORTATION COMMITTEE REPORT relative to the feasibility of utilizing Metro Local Return Funding for a transportation shuttle that would serve as a first/last mile option from the major transportation options in proximity to connect to business in the innov818 Zone (Zone).",
  },
  {
    title:
      "Restaurant Beverage Program / Restaurant Beverage Program-Sensitive Use Zone / Streamlined Permitting Land Use Regulatory Process / Council District 15",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=17-0981-S1",
    description:
      "PLANNING AND LAND USE MANAGEMENT COMMITTEE REPORT relative to activating a new streamlined permitting land use regulatory process, the Restaurant Beverage Program and Restaurant Beverage Program-Sensitive Use Zone, in Council District 15.",
  },
  {
    title:
      "Komar Distribution Services, FTZ 202, Site 20B / Foreign-Trade Zone / Harbor Department",
    url: "https://cityclerk.lacity.org/lacityclerkconnect/index.cfm?fa=ccfi.viewrecord&cfnumber=11-1242",
    description:
      "CATEGORICAL EXEMPTION and INNOVATION, GRANTS, TECHNOLOGY, COMMERCE, AND TRADE COMMITTEE REPORT relative to the first amendment to the Foreign-Trade Zone (FTZ) agreement with Komar Distribution Services (Komar) for FTZ 202, Site 20B.",
  },
];
