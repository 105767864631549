import AddressSearchSection from "../../features/AddressSearchSection/AddressSearchSection";
import PlanningAndZoning from "../../features/PlanningAndZoning/PlanningAndZoning";
import PermittingTimeline from "../../features/PermittingTimeline/PermittingTimeline";
import AnswerToUserQuestion from "../../features/AnswerToUserQuestion/AnswerToUserQuestion";
import RelevantMotions from "../../features/RelevantMotions/RelevantMotions";
import Jurisdiction from "../../features/Jurisdiction/Jurisdiction";
import { useAppSelector } from "../../app/hooks";
import { twMerge } from "tailwind-merge";
import SideNav from "../../components/ui/SideNav/SideNav";
import ThreeColumnLayout from "../../layouts/ThreeColumnLayout";
import { selectHasEndpointData } from "../../features/AddressSearchSection/addressSearchSectionSlice";
import TextSkeleton from "../../components/ui/Loaders/TextSkeleton";
import useFilterSideNavSections from "./hooks/useFilterSideNavSections";
import FixedSideNavPopover from "./components/FixedSideNavPopover/FixedSideNavPopover";
import Demo from "../../features/_Demo/Demo";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../helpers/constants";

interface AddressSearchProps {
  isDemo?: boolean
}

const AddressSearch = ({isDemo}: AddressSearchProps) => {
  const location = useLocation();

  const {isLoading, hasReportBeenGenerated} = useAppSelector(
    (state) => state.addressSearch,
  );

  const hasEndpointData = useAppSelector(selectHasEndpointData);

  const sideNavSections = useFilterSideNavSections();

  const leftColRender = isLoading ? (
    TextSkeleton({ lines: 4, lineWidth: 70 })
  ) : sideNavSections.length > 0 ? (
    <SideNav className="sticky top-6" sections={sideNavSections} />
  ) : (
    <></>
  );
  return (
    <div className={twMerge("mx-auto w-full")}>
      <AddressSearchSection />

      {isDemo ? (
        <Demo />
      ) : (
        <ThreeColumnLayout
          leftCol={leftColRender}
          className={twMerge(
            "defaultPageStyle",
            (hasEndpointData || hasReportBeenGenerated || isLoading) &&
              "md:mt-[75px] lg:mt-[150px]",
          )}
        >
          <>
            <FixedSideNavPopover sections={sideNavSections} />
            <AnswerToUserQuestion />
            <PlanningAndZoning />
            <Jurisdiction />
            <PermittingTimeline />
            <RelevantMotions />
          </>
        </ThreeColumnLayout>
      )}
    </div>
  );
};

export default AddressSearch;
