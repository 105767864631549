type AnnotationType = {
  completeLink: string;
  pageNumberRangeStart: number;
  pageNumberRangeEnd?: number;
  annotationStub: string;
  fileName?: string;

  parsed: boolean;
};

export type MockAnswerType = {
  title: string;
  isInitiallyOpen: boolean;
  answer: string;
  userData: boolean;
  sources?: AnnotationType[];
  additonalSources?: AnnotationType[];
};

export const questionOne: MockAnswerType = {
  title: "Are ADU’s allowed on this property?",
  isInitiallyOpen: false,
  userData: true,
  answer:'{"answer": "Accessory Dwelling Units (ADUs) in the RD1.5-1-O Zone must adhere to specific criteria including setbacks, height limits, and parking requirements. ADUs must comply with the general development standards such as a minimum lot area of 1,500 sq-ft per dwelling unit, a maximum height of 30 feet, and a minimum of two parking spaces per unit [[summary_of_zoning_regulations.pdf?annotation=file-summary_of_zoning_regulations_pdf-73756D6D6172795F6F665F7A6F6E696E675F726567756C6174696F6E732E706466-page-10]]. Additionally, ADUs must meet the specific requirements of the Venice Coastal Zone Specific Plan, which includes design standards, height limitations, and parking regulations [[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-12]].",      "additional_info": "The Venice Community Plan emphasizes maintaining the character of residential neighborhoods and ensuring new developments, including ADUs, are compatible with existing structures in terms of scale and design [[venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-27]].",      "sources": [        "summary_of_zoning_regulations.pdf?annotation=file-summary_of_zoning_regulations_pdf-73756D6D6172795F6F665F7A6F6E696E675F726567756C6174696F6E732E706466-page-10",        "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-12","venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-27"]}',
  sources: [],
  additonalSources: [],
};

export const questionTwo: MockAnswerType = {
  title: "What are the signage requirements on this site?",
  isInitiallyOpen: false,
  userData: false,
  answer:
    "{\n" +
    "  \"answer\": \"Signage design requirements for this site include the following: No billboards or off-premise commercial signs are permitted[[venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-80]]. All sign lighting shall be designed to minimize glare and prevent light pollution[[venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-200]]. Billboards and rooftop signs are prohibited within the Specific Plan area[[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-12]]. No sign shall be constructed, erected, maintained, or placed on the premises except those required by law or ordinance in connection with the drilling or maintenance of the well[[supplemental_O.pdf?annotation=file-supplemental_O_pdf-737570706C656D656E74616C5F4F2E706466-page-12]].\",\n" +
    "  \"additional_info\": \"All signage must complement and not detract from adjacent commercial and residential uses[[venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-93]].\",\n" +
    "  \"sources\": [\n" +
    "    \"venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-80\",\n" +
    "    \"venluptxt.pdf?annotation=file-venluptxt_pdf-76656E6C75707478742E706466-page-200\",\n" +
    "    \"Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-12\",\n" +
    "    \"supplemental_O.pdf?annotation=file-supplemental_O_pdf-737570706C656D656E74616C5F4F2E706466-page-12\",\n" +
    "    \"venice.pdf?annotation=file-venice_pdf-76656E6963652E706466-page-93\"\n" +
    "  ]\n" +
    "}",
};

export const questionThree: MockAnswerType = {
  title:
    "Are there special parking requirements for this site?",
  isInitiallyOpen: false,
  userData: true,
  answer: "{\n" +
    "  \"answer\": \"Yes, there are special parking requirements for this site. All Venice Coastal Development Projects must provide at least one pedestrian entrance into each business or use for each Store Frontage, and ground floor exterior building walls that face rear parking areas must provide a pedestrian entrance into the building [[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-22]]. Additionally, any new parking structure or surface parking lot must be landscaped according to specific standards, including a three-foot wide landscaped area along the perimeter of the lot abutting a public street and a three-foot high decorative masonry wall [[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-26]]. For projects in the Beach Impact Zone, additional parking spaces are required, and fees may be paid in lieu of providing some parking spaces [[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-31]]. Shared parking may also be permitted under certain conditions [[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-31]].\",\n" +
    "  \"sources\": [\n" +
    "    \"Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-22\",\n" +
    "    \"Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-26\",\n" +
    "    \"Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-31\"\n" +
    "  ]\n" +
    "}",
};

export const questionFour: MockAnswerType = {
  title: "Are there open space design requirements on this site?",
  isInitiallyOpen: true,
  userData: false,
  answer:
    '{\n' +
    '  "answer": "Yes, there are design requirements for open space on this site. Any open portion of the lot which is not used for buildings, parkways, driveways, or other access features must be landscaped. A landscape development plan prepared by a State licensed landscape architect, State licensed architect, or landscape contractor must be submitted to the Director of Planning for review and approval. This plan must include the location of a permanent underground sprinkler system designed to ensure complete coverage of all plant materials [[venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-25]].",\n' +
    '  "additional_info": "In commercial zones, any open portion of the lot not used for buildings, parkways, driveways, or other access features must be landscaped. A landscape development plan must be submitted to the Director of Planning for review and approval, including a permanent underground sprinkler system [[Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-24]].",\n' +
    '  "sources": [\n' +
    '    "venice_coastal_specific_plan.pdf?annotation=file-venice_coastal_specific_plan_pdf-76656E6963655F636F617374616C5F73706563696669635F706C616E2E706466-page-25",\n' +
    '    "Venice_Coastal_Zone_Specific_Plan.pdf?annotation=file-Venice_Coastal_Zone_Specific_Plan_pdf-56656E6963655F436F617374616C5F5A6F6E655F53706563696669635F506C616E2E706466-page-24"\n' +
    '  ]\n' +
    '}',
};

export const dataArray: MockAnswerType[] = [
  questionOne,
  questionTwo,
  questionThree,
  questionFour,
];
