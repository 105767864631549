import React from "react";
import AccordionSkeleton from "../../../../components/ui/Loaders/AccordionSkeleton";
import ReportSectionSkeleton from "../../../../components/ui/Loaders/ReportSectionSkeleton";
import { EnvironmentalInfo } from "../../helpers/demoSectionInfo";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";
import ListDetailsSkeleton from "../../../../components/ui/Loaders/ListDetailsSkeleton";

export default function SupplementarySourcesViewerSkeleton() {
  return (
    <AccordionSkeleton title={EnvironmentalInfo.title}>
      <AccordionSectionSpacer>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
        <ListDetailsSkeleton />
      </AccordionSectionSpacer>
    </AccordionSkeleton>
  );
}
