import { SectionInfoType } from "../../../global/types";

export const AddressAndJurisdictionInfo: SectionInfoType = {
  id: "address-and-jurisdiction",
  title: "Address & Jurisdiction",
};

export const PlanningAndZoningInfo: SectionInfoType = {
  id: "planning-and-zoning",
  title: "Planning & Zoning",
};

export const EntitlementInfo: SectionInfoType = {
  id: "entitlement",
  title: "Entitlement",
};

export const EnvironmentalInfo: SectionInfoType = {
  id: "environmental",
  title: "Environmental",
};

export const PermittingTimelinesInfo: SectionInfoType = {
  id: "permitting-timelines",
  title: "Permitting Timelines",
};

export const SupplementarySourcesViewerInfo: SectionInfoType = {
  id: "supplementary-sources-viewer",
  title: "Supplementary Sources Viewer",
};

export const SavedSearchesInfo: SectionInfoType = {
  id: "saved-searches",
  title: "Saved Searches",
};

export const AllSearchAddressInfos: SectionInfoType[] = [
  AddressAndJurisdictionInfo,
  PlanningAndZoningInfo,
  EntitlementInfo,
  EnvironmentalInfo,
  PermittingTimelinesInfo,
  SupplementarySourcesViewerInfo,
  SavedSearchesInfo,
];
