import {ChecklistRequest, submitChecklist} from "../../../components/ChecklistBuilder/api";
import React, {useEffect, useState} from "react";
import {validateJsonFormat} from "../../../components/ChecklistBuilder/jsonValidation";
import {ChecklistOptions, QueryInput} from "../../../components/ChecklistBuilder";
import {supabase} from "../../../api";
import StoredReportView from "../../../components/ChecklistBuilder/StoredReportView";
import {useLocation, useParams} from "react-router-dom";
import {Save20Filled} from "@fluentui/react-icons";
import {forEach} from "@react-google-maps/api/dist/utils/foreach";

interface Location {
  address?: string;
  lat?: number;
  lng?: number;
}

interface FormState {
  location: Location | null;
  intermediatePrompt?: string;
  finalizedPrompt?: string;
}

class StoredSearch {
  constructor(
    public id: number = 0,
    public query: string = '',
    public outputFormat: string = '',
    public title: string = '',
  ) {
  }

  static fromJson(json: any): StoredSearch {
    return new StoredSearch(
      json.id,
      json.query,
      json.output_format,
      json.title
    );
  }

  toJson(search: StoredSearch): object {
    const idDef = search.id !== 0 ? {id: search.id} : {};

    return {
      ...idDef,
      query: search.query,
      output_format: search.outputFormat,
      title: search.title
    };
  }
}

class ChecklistFetcher {
  async getChecklistName(checklistId: number): Promise<string> {
    let {data, error} = await supabase.from('checklists')
      .select("name")
      .eq('id', checklistId)
      .single();
    if (error) throw error;
    return data?.name ?? '';
  }

  async getChecklistItems(checklistId: number): Promise<StoredSearch[]> {
    if (checklistId === 0) {
      return [];
    }
    const {data, error} = await supabase.rpc('get_stored_searches_for_checklist_id', {
      searched_checklist_id: checklistId,
    })

    if (error) {
      console.error('Error fetching stored searches for checklist:', error)
      return []
    }

    return (data as any[]).map(StoredSearch.fromJson);
  }

  async updateChecklistItems(items: StoredSearch[], checklistId: number, inOrder = false): Promise<void> {
    try {
      // First, upsert the StoredSearch items
      const {data, error} = await supabase.from('stored_searches')
        .upsert(items.map(item => item.toJson(item)))
        .select();


      if (error) throw error;

      if (!data) throw new Error('No data returned from upsert operation');

      for (let i = 0; i < data.length; i++) {
        items[i].id = data[i].id;
      }

      // Then, upsert the checklist_stored_searches entries
      const {error: linkError} = await supabase.from('checklist_stored_searches')
        .upsert(
          data.map((item: any, index) => ({
            'checklist_id': checklistId,
            'stored_search_id': item.id == 0 ? null : item.id,
            'list_order': inOrder ? index : 999
          }))
        );

      if (linkError) throw linkError;

      console.log('Checklist items updated successfully');
    } catch (error) {
      console.error('Error updating stored searches for checklist:', error);
      throw error; // Re-throw the error for the caller to handle
    }
  }

  async deleteChecklistItems(itemsToDelete: StoredSearch[]): Promise<void> {
    try {
      // Delete the entries from checklist_stored_searches
      const {error: unlinkError} = await supabase
        .from('checklist_stored_searches')
        .delete()
        .in('stored_search_id', itemsToDelete.map(item => item.id));

      if (unlinkError) throw unlinkError;

      // Delete the StoredSearch items
      const {error: deleteError} = await supabase
        .from('stored_searches')
        .delete()
        .in('id', itemsToDelete.map(item => item.id));

      if (deleteError) throw deleteError;

      console.log('Checklist items deleted successfully');
    } catch (error) {
      console.error('Error deleting stored searches for checklist:', error);
      throw error; // Re-throw the error for the caller to handle
    }
  }

}

interface ChecklistBuilderProps {
  create?: boolean
}

function ChecklistBuilder({create}: ChecklistBuilderProps) {
  const [queries, setQueries] = useState<StoredSearch[]>([
    new StoredSearch()
  ]);

  const [formState, setFormState] = useState<FormState>({
    location: null,
    intermediatePrompt: '',
    finalizedPrompt: ''
  });

  const [response, setResponse] = useState<any>(undefined);
  const [reportId, setReportId] = useState<number>();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const {checklistIdParam} = useParams<{ checklistIdParam?: string }>();
  const [checklistId, setChecklistId] = useState<number>(create ? 0 : (Number(checklistIdParam) || 10));

  const [checklistName, setChecklistName] = useState<string>();

  useEffect(() => {
    if (!create && checklistIdParam == undefined) {
      setChecklistId(10); // default alpha checklist
      return;
    }
    setChecklistId(Number(checklistIdParam))
  }, [checklistIdParam]);

  const handleAddQuery = () => {
    if (queries.length < 10) {
      setQueries([...queries, new StoredSearch()]);
    }
  };

  useEffect(() => {
    let fetcher = new ChecklistFetcher();
    fetcher.getChecklistItems(checklistId).then((items) => {
      setQueries(items)
      validateQueries()
    })
    fetcher.getChecklistName(checklistId).then(setChecklistName);
  }, [checklistId]);

  const handleQueryChange = (index: number, field: string, value: string) => {
    const newQueries = [...queries];
    const updatedSearch = new StoredSearch(
      newQueries[index].id,
      field === 'query' ? value : newQueries[index].query,
      field === 'outputFormat' ? value : newQueries[index].outputFormat,
      field === 'title' ? value : newQueries[index].title,
    );
    newQueries[index] = updatedSearch;
    setQueries(newQueries);
    setError('');
  };

  const handleQuerySave = (index: number) => {
    let fetcher = new ChecklistFetcher();
    fetcher.updateChecklistItems([queries[index]], checklistId).then(() => {
    })
  }

  const createChecklistIfNew = async () => {
    if (!create) return;
    const {data: rows, error} = await supabase.from('checklists')
      .insert({
        'name': checklistName?.length ?? "New Checklist",
      })
      .select('id')
      .single();

    if (error) {
      console.error('Error creating checklist:', error);
      return 0;
    }
    const createdChecklistId = rows?.id || 0;
    // Set the URL to include the new checklist ID
    window.history.pushState({}, '', `/admin/checklist/${createdChecklistId}`);
    setChecklistId(createdChecklistId);
    create = false;
    return createdChecklistId;
  }

  const handleSaveChecklist = async () => {
    let fetcher = new ChecklistFetcher();
    let createdChecklistId = checklistId || await createChecklistIfNew();
    const {error: error} = await supabase.from('checklists').upsert(
      {
        'id': checklistId,
        'name': checklistName,
        'prompt_overrides': {
          'intermediate_prompt': formState.intermediatePrompt,
          'finalized_prompt': formState.finalizedPrompt,
        }
      });

    fetcher.updateChecklistItems(queries, createdChecklistId, true).then();

    setQueries(queries.map((query) => {
      query.id = createdChecklistId;
      return query;
    }));
  }

  const handleDeleteQuery = (index: number) => {
    if (queries.length > 1) {
      setQueries(queries.filter((_, i) => i !== index));
      setError('');
      let fetcher = new ChecklistFetcher();
      fetcher.deleteChecklistItems([queries[index]]).then(r => {
      });
    }
  };

  const handleFormStateChange = (updates: Partial<FormState>) => {
    setFormState((prev) => {
      const newState = {...prev, ...updates};
      // Ensure that location is properly updated
      if (updates.location) {
        newState.location = {...prev.location, ...updates.location};
      }
      return newState;
    });
    setError('');
  };

  const validateQueries = (): boolean => {
    try {
      // Validate all queries have content
      const emptyQueries = queries.find(q => !q.query.trim() || !q.outputFormat.trim());
      if (emptyQueries) {
        throw new Error('All queries and formats must be filled out');
      }

      // Validate all JSON formats
      queries.forEach((q, index) => {
        try {
          validateJsonFormat(q.outputFormat);
        } catch (error) {
          throw new Error(`Query ${index + 1}: ${error instanceof Error ? error.message : 'Invalid JSON format'}`);
        }
      });

      return true;
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Validation failed');
      return false;
    }
  };

  const handleSubmit = async () => {
    setError('');

    if (!validateQueries()) {
      return;
    }
    setIsLoading(true);

    try {
      const checklistRequests: ChecklistRequest[] = queries.map(q => ({
        query: q.query,
        format: q.outputFormat,
        title: q.title
      }));

      const response = await submitChecklist(checklistRequests,
        [formState.location!.lat!, formState.location!.lng!], formState.location!.address,
        formState.intermediatePrompt, formState.finalizedPrompt);
      let response_data: object[] = response.results.map(response => {
        return {
          answer: JSON.parse(response?.choices[0]['message']['content'] ?? '{}'),
          id: 0
        };
      })

      setReportId(response.report_id)

      setResponse(response_data);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to process queries');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto space-y-8">
        <div className="flex items-center justify-center space-x-4">
          <div className="flex items-center">
            <input
              type="text"
              value={checklistName ?? "Checklist Item Builder"}
              onChange={(e) => setChecklistName(e.target.value)}
              onBlur={() => {
                // You might want to add a function here to update the checklist name in the database
                // For example: updateChecklistNameInDB(checklistId, checklistName);
              }}
              className="text-3xl font-bold text-gray-900 bg-transparent border-b-2 border-transparent focus:border-blue-500 focus:outline-none bg-white"
            />
          </div>

        </div>
        <p className="text-gray-600 text-center">Build your questions with JSON output formats</p>
        <ChecklistOptions
          formState={formState}
          onChange={handleFormStateChange}
          checklistId={checklistId}
        />

        <div className="space-y-6">
          {queries.map((query, index) => (
            <QueryInput
              key={index}
              query={query}
              index={index}
              onChange={handleQueryChange}
              onDelete={handleDeleteQuery}
            />
          ))}

          {queries.length < 10 && (
            <button
              onClick={handleAddQuery}
              className="w-full py-4 border-2 border-dashed border-gray-300 rounded-lg text-gray-600 hover:border-blue-500 hover:text-blue-500 transition-colors flex items-center justify-center gap-2"
            >
              {/*<PlusCircle size={20} />*/}
              Add Query
            </button>
          )}
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                {/*<AlertCircle className="h-5 w-5 text-red-400" />*/}
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-center gap-2">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className="bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
          >
            {isLoading ? 'Processing...' : 'Run Checklist'}
          </button>
          <button
            className="p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            onClick={handleSaveChecklist}
            // disabled={loading}
          >
            <Save20Filled className="w-6 h-6"></Save20Filled>Save
          </button>
        </div>

        {response && !isLoading && (
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-900">Response</h2>

            {reportId && (
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  readOnly
                  value={`${window.location.origin}/admin/report/${reportId}`}
                  className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                <button
                  onClick={() => navigator.clipboard.writeText(`${window.location.origin}/admin/report/${reportId}`)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Copy
                </button>
              </div>
            )}

            <StoredReportView propAnswers={response}/>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChecklistBuilder;
