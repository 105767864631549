import React from "react";
import AccordionSkeleton from "../../../components/ui/Loaders/AccordionSkeleton";
import ReportSectionSkeleton from "../../../components/ui/Loaders/ReportSectionSkeleton";
import TextSkeleton from "../../../components/ui/Loaders/TextSkeleton";
import { AnswerToUserQuestionInfo } from "../../../helpers/constants";
import AccordionSectionSpacer from "../../../components/ui/Accordion/AccordionSectionSpacer";
import ListDetailsSkeleton from "../../../components/ui/Loaders/ListDetailsSkeleton";

export default function AnswerToUserQuestionSkeleton() {
  return (
    <AccordionSkeleton title={AnswerToUserQuestionInfo.title}>
      <AccordionSectionSpacer>
        <ReportSectionSkeleton title>
          <TextSkeleton lines={5} />
        </ReportSectionSkeleton>
        <ListDetailsSkeleton />
      </AccordionSectionSpacer>
    </AccordionSkeleton>
  );
}
