import React from "react";
import { twMerge } from "tailwind-merge";
import { SpinnerIos20Filled } from "@fluentui/react-icons";

type IconType = {
  icon: JSX.Element;
  className?: string;
  onClick?: (arg?: any) => any;
  isDisabled?: boolean;
  isLoading?: boolean;
};

type Props = {
  type?: "submit" | "reset" | "button";
  variant?: "hero" | "hero-outline";
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: (arg?: any) => any;
  className?: string;
  // for rendering icon-only button pass the icon to iconLeft
  iconLeft?: IconType;
  iconRight?: IconType;

  maxWidth?: "fit" | "full" | number;
  label?: string;
};

export default function HeroButton({
  type = "button",
  variant = "hero",
  isDisabled,
  isLoading,
  onClick,
  className,
  iconRight,
  iconLeft,
  maxWidth = "fit",
  label,
}: Props) {
  // const isHero = variant === "hero";
  // const isHeroOutline = variant === "hero-outline";
  // const isHeroType = isHeroOutline || isHero;

  const isOutline = variant.includes("outline");

  const variantClasses = (() => {
    switch (variant) {
      case "hero":
        return "transition-colors duration-300 bg-rf-very-bright-red enabled:hover:bg-rf-bright-red enabled:active:bg-rf-vibrant-red";
      case "hero-outline":
        return "transition-colors duration-300 enabled:hover:bg-rf-very-bright-red enabled:hover:bg-opacity-10 enabled:active:bg-rf-very-bright-red enabled:active:bg-opacity-20";
      default:
        return "transition-colors duration-300 bg-rf-very-bright-red enabled:hover:bg-rf-bright-red enabled:active:bg-rf-vibrant-red";
    }
  })();

  const handleBtnMaxWidth = (() => {
    if (typeof maxWidth === "number") return `${maxWidth}px`;

    switch (maxWidth) {
      case "fit":
        return "fit-content";
      case "full":
        return `100%`;
      default:
        return "auto";
    }
  })();

  const handleBtnMinWidth = (() => {
    if (typeof maxWidth === "number") return `${maxWidth}px`;

    if (label) return "fit-content";

    return "60px";
  })();

  const handleCentralButtonPadding = (() => {
    if (iconLeft && iconRight && !iconLeft.onClick && !iconRight.onClick)
      return "px-[80px]";
    if (iconLeft && iconRight && iconLeft.onClick && iconRight.onClick)
      return "px-3";

    if (iconLeft && iconRight && !iconLeft.onClick && iconRight.onClick)
      return "pl-[80px] pr-3";
    if (iconLeft && iconRight && iconLeft.onClick && !iconRight.onClick)
      return "pr-[80px] pl-3";

    if (iconLeft && !iconRight && !iconLeft.onClick) return "pl-[80px] pr-5";
    if (iconLeft && !iconRight && iconLeft.onClick) return "pl-3 pr-5";

    if (iconRight && !iconLeft && !iconRight.onClick) return "pr-[80px] pl-5";
    if (iconRight && !iconLeft && iconRight.onClick) return "pr-3 pl-5";

    if (!iconLeft && !iconRight) return "px-5";
  })();

  const handleOnclick = () => {
    if (isDisabled || isLoading || !onClick) return;
    onClick();
  };

  const renderIcon = (icon?: IconType, isRight?: boolean) => {
    if (!icon) return undefined;

    const iconBod = React.cloneElement(icon.icon, {
      className: twMerge(
        "leading-none shrink-0",
        !isOutline && "opacity-50",
        label ? `h-9 w-9` : "h-10 w-10",
        icon.className,
      ),
    });

    return icon.onClick ? (
      <button
        disabled={icon.isDisabled || icon.isLoading}
        type="button"
        className={twMerge(
          "block h-full px-2.5",
          variantClasses,
          (icon.isDisabled || icon.isLoading) &&
            "cursor-not-allowed opacity-75",

          label && (isRight ? "pl-3 pr-5" : "pl-5 pr-3"),
        )}
        onClick={icon.onClick}
      >
        {icon.isLoading ? (
          <SpinnerIos20Filled
            className={twMerge(
              "animate-spin",
              label ? `h-9 w-9` : "h-10 w-10",

              !isOutline && "opacity-50",
            )}
          />
        ) : (
          iconBod
        )}
      </button>
    ) : (
      <div
        className={twMerge(
          "pointer-events-none absolute",
          isDisabled && "cursor-not-allowed opacity-75",
          isRight ? "right-5" : "left-5",
        )}
      >
        {iconBod}
      </div>
    );
  };
  console.log("iconLeft:", iconLeft?.onClick);
  return (
    <div
      style={{
        maxWidth: handleBtnMaxWidth,
        width: "100%",
        minWidth: handleBtnMinWidth,
      }}
      className={twMerge(
        "relative h-[60px] shrink-0 overflow-clip rounded-full text-white shadow-[0_0_10px_0px_rgba(248,72,29,0.5)]",

        isOutline &&
          "border-[1px] border-rf-very-bright-red text-rf-very-bright-red",

        className,
      )}
    >
      <div
        className={twMerge(
          "relative flex h-full items-center justify-between leading-none",

          !label && "justify-center",
        )}
      >
        {renderIcon(iconLeft)}

        {label && (
          <button
            type={type}
            onClick={handleOnclick}
            disabled={isDisabled || isLoading}
            className={twMerge(
              "block h-full w-full",

              variantClasses,
              (isDisabled || isLoading) && "cursor-not-allowed opacity-75",
              handleCentralButtonPadding,
            )}
          >
            <p
              className={twMerge(
                "line-clamp-1 select-none break-all text-[16px] font-semibold leading-normal",
                isLoading ? "invisible" : "visible",
              )}
            >
              {label}
            </p>
          </button>
        )}

        <div
          className={twMerge(
            "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] leading-none",
            !isLoading ? "invisible" : "visible",
          )}
        >
          <SpinnerIos20Filled
            className={twMerge(
              "h-9 w-9 animate-spin cursor-not-allowed",

              !isOutline && "opacity-50",
            )}
          />
        </div>

        {label && renderIcon(iconRight, true)}
      </div>
    </div>
  );
}
