import React, { useRef, useMemo } from "react";
import { MockAnswerType } from "./data/data";
import DocumentRenderer from "../../../AnswerToUserQuestion/components/DocumentRenderer/DocumentRenderer";
import { DocumentRendererHandle } from "../../../AnswerToUserQuestion/components/DocumentRenderer/DocumentRenderer";
import { ParsedAnnotation } from "../../../../components/Answer";
import { parseAnswerToHtml } from "../../../../components/Answer/AnswerParser";
import { AddressLookupResponse } from "../../../../api";
import { useAppSelector } from "../../../../app/hooks";

type Props = {
  data: MockAnswerType;
};

export default function SavedSection({ data }: Props) {
  const DocumentRendererRef = useRef<DocumentRendererHandle>(null);
  const { isLoading } = useAppSelector((state) => state.demo);

  const navigateToCitation = (annotation: string): void => {
    const parsed = new ParsedAnnotation(annotation);
    if (parsed && DocumentRendererRef.current) {
      DocumentRendererRef.current.gotoAnnotation(parsed.getFirstAnnotation());
    }
  };

  const { parsedAnswerElements, sources, additionalSources } = useMemo(() => {
    let addressLookupResp: AddressLookupResponse | undefined;
    let parsedAnswerElementsResult: {
      citations: string[];
      answerElements: JSX.Element[];
    } = { citations: [], answerElements: [] }; // Initialize with default values
    let sourcesResult: ParsedAnnotation[] = [];
    let additionalSourcesResult: ParsedAnnotation[] = [];

    if (!isLoading) {
      try {
        addressLookupResp = JSON.parse(data.answer);
        addressLookupResp = addressLookupResp!;
        parsedAnswerElementsResult = parseAnswerToHtml(
          addressLookupResp["answer"],
          false,
          navigateToCitation,
        );

        if (addressLookupResp["sources"] != undefined) {
          addressLookupResp["sources"].forEach((source) => {
            sourcesResult.push(new ParsedAnnotation(source));
          });
        }
        if (addressLookupResp["additional_sources"] != undefined) {
          addressLookupResp["additional_sources"].forEach((source) => {
            additionalSourcesResult.push(new ParsedAnnotation(source));
          });
        }
      } catch (e) {
        console.error("Error parsing message content:", e);
        // Handle the error, maybe log it
        parsedAnswerElementsResult = parseAnswerToHtml(
          data.answer,
          false,
          navigateToCitation,
        );
      }
    } else {
      parsedAnswerElementsResult = parseAnswerToHtml(
        "",
        false,
        navigateToCitation,
      );
    }

    return {
      parsedAnswerElements: parsedAnswerElementsResult,
      sources: sourcesResult,
      additionalSources: additionalSourcesResult,
    };
  }, [isLoading, data.answer, navigateToCitation]); // Re-compute when isLoading or messageContent changes

  return (
    <DocumentRenderer
      desc={parsedAnswerElements}
      ref={DocumentRendererRef}
      citedSources={sources}
      title={data.title}
      additionalSources={additionalSources}
      collapsible={true}
      isInitiallyOpen={data.isInitiallyOpen}
      limitMaxHeight={false}
    />
  );
}
