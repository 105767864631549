import React from "react";
import Accordion from "../../../../components/ui/Accordion/Accordion";
import { PermittingTimelinesInfo } from "../../helpers/demoSectionInfo";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";
import ReportSection from "../../../../components/ui/ReportSection/ReportSection";
import KeyValueSection from "../../../../components/ui/KeyValueSection/KeyValueSection";
import { summaryData, graphData } from "./data/data";
import ChartTypeToggle from "../../../PermittingTimeline/components/ChartTypeToggle";
import { useAppSelector } from "../../../../app/hooks";
import PermittingTimelineSkeleton from "../../../PermittingTimeline/components/PermittingTimelineSkeleton";

export default function PermittingTimelines() {
  const { isLoading } = useAppSelector((state) => state.demo);

  if (isLoading) return <PermittingTimelineSkeleton />;

  const tickerText =
    "Median " + (graphData.zone_filter ? "Zone: " + graphData.zone_filter : "");

  return (
    <Accordion
      title={PermittingTimelinesInfo.title}
      id={PermittingTimelinesInfo.id}
    >
      <AccordionSectionSpacer>
        {/* Summary */}
        <ReportSection
          title="Summary"
          secondaryTitle="Low Medium II Residential"
          collapsible
        >
          <KeyValueSection
            valueHeader="Median Approval Time"
            keyHeader="Permit Type"
            content={summaryData}
          />
        </ReportSection>

        {/* Building Permits  */}
        <ReportSection
          isInitiallyOpen={false}
          collapsible
          title="Building Permits"
          secondaryTitle="0 to 280 weeks"
        >
          <p className={"body-font text-center font-bold"}>
            Distribution of Approval Timelines by Plan Check Approval Type
          </p>
          <ChartTypeToggle
            tickerText={tickerText}
            barChartData={graphData.bar_data_building_business_unit}
            medianTickerValue={graphData.building_median}
            boxPlotsData={graphData.box_data_building_business_unit}
          />

          <p className={"body-font pt-3 text-center font-bold"}>
            Distribution of Approval Timelines by Plan Check Permit Type
          </p>
          <ChartTypeToggle
            defaultBarChart={false}
            tickerText={tickerText}
            barChartData={graphData.bar_data_building_permit_type}
            medianTickerValue={graphData.building_median}
            boxPlotsData={graphData.box_data_building_permit_type}
          />
        </ReportSection>

        {/* Mechanical Permits */}
        <ReportSection
          isInitiallyOpen={false}
          collapsible
          title="Mechanical Permits"
          secondaryTitle="0 to 146 weeks"
        >
          <p className={"body-font pt-3 text-center font-bold"}>
            Distribution of Approval Timelines by Plan Check Approval Type
          </p>
          <ChartTypeToggle
            tickerText={tickerText}
            barChartData={graphData.bar_data_mechanical_business_unit}
            medianTickerValue={3}
            boxPlotsData={graphData.box_data_mechanical_business_unit}
          />

          <p className={"body-font pt-3 text-center font-bold"}>
            Distribution of Approval Timelines by Plan Check Permit Type
          </p>
          <ChartTypeToggle
            tickerText={tickerText}
            barChartData={graphData.bar_data_mechanical_permit_type}
            medianTickerValue={3}
            boxPlotsData={graphData.box_data_mechanical_permit_type}
          />
        </ReportSection>

        {/* Electrical Permits */}
        <ReportSection
          isInitiallyOpen={false}
          collapsible
          title="Electrical Permits"
          secondaryTitle="0 to 147 weeks"
        >
          <p className={"body-font pt-3 text-center font-bold"}>
            Distribution of Approval Timelines by Plan Check Approval Type
          </p>
          <ChartTypeToggle
            tickerText={tickerText}
            barChartData={graphData.bar_data_electrical_business_unit}
            medianTickerValue={4}
            boxPlotsData={graphData.box_data_electrical_business_unit}
          />

          <p className={"body-font pt-3 text-center font-bold"}>
            Distribution of Approval Timelines by Plan Check Permit Type
          </p>
          <ChartTypeToggle
            defaultBarChart={false}
            tickerText={tickerText}
            barChartData={graphData.bar_data_electrical_permit_type}
            medianTickerValue={4}
            boxPlotsData={graphData.box_data_electrical_permit_type}
          />
        </ReportSection>
      </AccordionSectionSpacer>
    </Accordion>
  );
}
