import React from "react";
import DropdownMenu, { DropdownOption } from "../ui/DropdownMenu/DropdownMenu";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../helpers/constants";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Dialog } from "@fluentui/react";
import { supabase } from "../../api/db";
import { useEffect, useState } from "react";
import { Person24Filled } from "@fluentui/react-icons";
import { useAppSelector } from "../../app/hooks";
import Button from "../ui/Buttons/Button";

export default function ProfileDropdown() {
  const navigate = useNavigate();

  const { session } = useAppSelector((state) => state.user);
  const [firstName, setFirstName] = useState("");

  useEffect(
    function () {
      if (session?.user?.id) {
        supabase
          .from("users")
          .select("first_name")
          .eq("id", session.user.id) // This only matters for admins who get full visibility
          .single() // Expecting only one user with this ID
          .then(({ data: user, error }) => {
            // Destructure data and error
            if (error) {
              console.error("Error getting user:", error);
            } else if (user) {
              setFirstName(user.first_name);
            }
          });
      }
    },
    [session],
  );

  const options: DropdownOption[] = [
    {
      type: "header",
      label: `Hello, ${firstName ?? session?.user?.email ?? ""}`,
      hidden: !firstName && !session?.user?.email,
    },

    {
      type: "option",
      label: "Profile Settings",
      onClick: () => navigate(ROUTES.account),
    },
    {
      type: "divider",
    },
    {
      type: "option",
      label: "Sign Out",
      onClick: () => supabase.auth.signOut().then(() => navigate(ROUTES.home)),
    },
  ];

  const trigger = (
    <Button
      variant="ghost"
      icon={{
        icon: <Person24Filled />,
        rounded: true,
        className: "text-white",
      }}
    />
  );

  return !session ? (
    <Dialog hidden={false}>
      <Auth
        providers={["google"]}
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa }}
      />
    </Dialog>
  ) : (
    <DropdownMenu trigger={trigger} options={options} />
  );
}
