import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialStateType = {
  isLoading: boolean;
  isFetched: boolean;
};

const initialState: InitialStateType = {
  isLoading: false,
  isFetched: false,
};

const demoSlice = createSlice({
  name: "demo",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setFetched(state, action: PayloadAction<boolean>) {
      state.isFetched = action.payload;
    },
    resetDemoState() {
      return initialState;
    },
  },
});

export const { setLoading, setFetched } = demoSlice.actions;

export default demoSlice.reducer;
