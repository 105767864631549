import React from "react";
import Accordion from "../../../../components/ui/Accordion/Accordion";
import { AddressAndJurisdictionInfo } from "../../helpers/demoSectionInfo";
import ReportSection from "../../../../components/ui/ReportSection/ReportSection";
import KeyValueSection from "../../../../components/ui/KeyValueSection/KeyValueSection";
import { AddressKVData, JurisdictionalKVData } from "./data/data";
import AddressMap from "./AddressMap";
import { useAppSelector } from "../../../../app/hooks";
import AddressAndJurisdictionSkeleton from "./AddressAndJurisdictionSkeleton";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";

export default function AddressAndJurisdiction() {
  const { isLoading } = useAppSelector((state) => state.demo);

  if (isLoading) return <AddressAndJurisdictionSkeleton />;

  return (
    <Accordion
      title={AddressAndJurisdictionInfo.title}
      id={AddressAndJurisdictionInfo.id}
    >
      {/* Address */}
      <AccordionSectionSpacer>
        <ReportSection title="Address" collapsible>
          <div className="flex w-full flex-col gap-3 lg:flex-row">
            <div className="w-full lg:w-[60%] xl:w-[50%]">
              <KeyValueSection compact={true} content={AddressKVData} />
            </div>
            {
              <AddressMap
                coordinates={{
                  lat: 33.9841478,
                  lng: -118.4590855,
                }}
              />
            }
          </div>
        </ReportSection>

        {/* Jurisdictional */}
        <ReportSection title="Jurisdictional" collapsible>
          <div className="flex w-full flex-col gap-3 lg:flex-row">
            <div className="w-full lg:w-[60%] xl:w-[50%]">
              <KeyValueSection compact={true} content={JurisdictionalKVData} />
            </div>
            {
              <AddressMap
                showMarker={false}
                defaultZoom={14}
                coordinates={{
                  lat: 33.9841478,
                  lng: -118.4590855,
                }}
              />
            }
          </div>
        </ReportSection>
      </AccordionSectionSpacer>
    </Accordion>
  );
}
