import React, {useState, useEffect, useCallback} from 'react';
import SearchAddress from '../SearchAddress/SearchAddress';
import {ChevronDown20Filled, ChevronUp20Filled, Save20Filled} from '@fluentui/react-icons';
import { fetchFinalizedPrompt, fetchIntermediatePrompt } from './api';
import {supabase} from "../../api";

interface Location {
  address?: string;
  lat?: number;
  lng?: number;
}

interface FormState {
  location: Location | null;
  intermediatePrompt?: string;
  finalizedPrompt?: string;
}

interface LocationSectionProps {
  formState: FormState;
  onChange: (updates: Partial<FormState>) => void;
  checklistId?: number;
}

export function ChecklistOptions({ formState, onChange, checklistId }: LocationSectionProps) {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(formState.location?.address || '');

  const savePrompts = async () => {

    const {data, error} = await supabase.from('checklists')
      .upsert({
        'id': checklistId,
        'prompt_overrides': {
          'intermediate_prompt': formState.intermediatePrompt,
          'finalized_prompt': formState.intermediatePrompt,
        }
      })

    if (error) throw error;
  };

  const loadPrompts = async () => {
    if(checklistId) {
      const {data, error} = await supabase.from('checklists')
        .select('*')
        .eq('id', checklistId)
        .single()
      if (error) throw error;

      if (data['prompt_overrides']) {
        let intermediatePrompt = data['prompt_overrides']['intermediate'] !== null
          ? data['prompt_overrides']['intermediate_prompt']
          : undefined;
        let finalizedPrompt = data['prompt_overrides']['finalized'] !== null
          ? data['prompt_overrides']['finalized_prompt']
          : undefined;
        if (intermediatePrompt || finalizedPrompt) {
          onChange({ intermediatePrompt });
          onChange({ finalizedPrompt });
          return
        }
      }
    }

    setLoading(true);
    try {
      const [intermediatePrompt, finalizedPrompt] = await Promise.all([
        fetchIntermediatePrompt(),
        fetchFinalizedPrompt()
      ]);

      if (!formState.intermediatePrompt) {
        onChange({ intermediatePrompt });
      }
      if (!formState.finalizedPrompt) {
        onChange({ finalizedPrompt });
      }
    } catch (error) {
      console.error('Failed to load prompts:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
      loadPrompts();
  }, [checklistId]);

  const handleLocationUpdate = (address: string) => {
    if (formState.location) {
      onChange({
        location: {
          address: address,
        }
      });
    } else {
      onChange({
        location: {
          address
        }
      });
    }
  };

  const handleCoordsUpdate = (coords: { lat: number; lng: number }) => {
    if (!formState.location) {
      formState.location = {address: '', lat: 0, lng: 0};
    }
    onChange({
      location: {
        lat: coords.lat,
        lng: coords.lng
      }
    });

  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md space-y-6">
      <div>
        <h2 className="text-lg font-medium text-gray-900 mb-4">Location</h2>
        <SearchAddress
          address={formState.location?.address || ''}
          setAddress={handleLocationUpdate}
          setCoords={handleCoordsUpdate}
          loading={loading}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </div>

      <div className="pt-4 border-t border-gray-200">
        <button
          onClick={() => setShowAdvanced(!showAdvanced)}
          className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
        >
          {showAdvanced ? <ChevronUp20Filled /> : <ChevronDown20Filled />}
          Advanced Options
        </button>

        {showAdvanced && (
          <div className={`mt-4 space-y-4 ${loading ? 'opacity-50' : ''}`}>
            <div>
              <div className={"flex flex-inline"}>
                <label htmlFor="intermediate-prompt" className="block text-sm font-medium text-gray-700 mb-1">
                  Intermediate Prompt
                </label>

                <button
                  onClick={savePrompts}
                  disabled={loading}
                >
                  <Save20Filled/>
                </button>
              </div>
              <textarea
                id="intermediate-prompt"
                value={formState.intermediatePrompt || ''}
                onChange={(e) => onChange({intermediatePrompt: e.target.value})}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Custom intermediate prompt..."
                rows={2}
                disabled={loading}
              />
            </div>

            <div>
              <div className={"flex flex-inline"}>
                <label htmlFor="finalized-prompt" className="block text-sm font-medium text-gray-700 mb-1">
                  Finalized Prompt
                </label>

                <button
                  onClick={savePrompts}
                  disabled={loading}
                >
                  <Save20Filled/>
                </button>
              </div>
              <textarea
                id="finalized-prompt"
                value={formState.finalizedPrompt || ''}
                onChange={(e) => onChange({finalizedPrompt: e.target.value})}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Custom finalized prompt..."
                rows={2}
                disabled={loading}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
