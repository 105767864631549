import {Document, Annotation, supabase, SiteInfoRequest, getHeaders} from "../../../api";
export class DocumentCRUD {
    async createDocument(document: Omit<Document, 'id' | 'created_at'>): Promise<any> {
        const {data, error} = await supabase
            .from('documents')
            .insert([document]);
        if (error) throw error;
        return data;
    }

    async getDocuments(): Promise<Document[]> {
        const {data, error} = await supabase
            .from('documents')
            .select('*')
            .order('id');
        if (error) throw error;
        return data;
    }
    async getDocument(fileName: string): Promise<Document> {
        const {data, error} = await supabase
            .from('documents')
            .select('*')
            .eq('id', fileName)
            .single()
        if (error) throw error;
        return data;
    }

    async updateDocument(id: string, document: Partial<Document>): Promise<any> {
        const {data, error} = await supabase
            .from('documents')
            .update(document)
            .eq('id', id);
        if (error) throw error;
        return data;
    }

    async deleteDocument(id: string): Promise<any> {
        const {data, error} = await supabase
            .from('documents')
            .delete()
            .eq('id', id);
        if (error) throw error;
        return data;
    }

    async getAnnotationsFromDocument(documentName: string): Promise<Annotation[]> {
        const {data, error} = await supabase
            .from('annotations')
            .select('*')
            .eq('filename', documentName)
            .order('id')
            .limit(10000)
            .returns<Annotation[]>();
        if (error) throw error;
        return data;
    }

    async getAllDocumentsForOverlay(overlayName: string): Promise<Document[]> {
        const {data, error} = await supabase
          .from('documents')
          .select('*')
          .eq('overlay', overlayName)
        if (error) throw error;
        return data;
    }

    async getAnnotationDetails(annotationIds: string[]): Promise<Response> {
        return await fetch(`/admin/get_annotation_details`, {
            method: "POST",
            headers: { ...(await getHeaders()), "Content-Type": "application/json" },
            body: JSON.stringify({
                "annotation_ids": annotationIds
            })
        });
    }

    async setInterpretationNote(annotationIds: string[], interpretationNote: string): Promise<Response> {
        return await fetch(`/admin/set_annotation_note`, {
            method: "POST",
            headers: { ...(await getHeaders()), "Content-Type": "application/json" },
            body: JSON.stringify({
                "annotation_ids": annotationIds,
                "interpretation_note": interpretationNote
            })
        });
    }

    async setSectionName(annotationIds: string[], sectionName: string): Promise<Response> {
        return await fetch(`/admin/set_section_name`, {
            method: "POST",
            headers: { ...(await getHeaders()), "Content-Type": "application/json" },
            body: JSON.stringify({
                "annotation_ids": annotationIds,
                "sectionName": sectionName
            })
        });
    }
}
