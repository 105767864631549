import React from "react";
import AccordionSkeleton from "../../../../components/ui/Loaders/AccordionSkeleton";
import TextSkeleton from "../../../../components/ui/Loaders/TextSkeleton";
import MapSkeleton from "../../../../components/ui/Loaders/MapSkeleton";
import ReportSectionSkeleton from "../../../../components/ui/Loaders/ReportSectionSkeleton";
import { AddressAndJurisdictionInfo } from "../../helpers/demoSectionInfo";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";

export default function AddressAndJurisdictionSkeleton() {
  return (
    <AccordionSkeleton title={AddressAndJurisdictionInfo.title}>
      <AccordionSectionSpacer>
        <ReportSectionSkeleton title>
          <div className="flex w-full flex-col gap-3 lg:flex-row">
            <div className="w-full lg:w-[60%] xl:w-[50%]">
              <TextSkeleton lines={12} columns={2} lineWidth={70} />
            </div>
            {<MapSkeleton className="w-full lg:w-[40%] xl:w-[50%]" />}
          </div>
        </ReportSectionSkeleton>
        <ReportSectionSkeleton title>
          <div className="flex w-full flex-col gap-3 lg:flex-row">
            <div className="w-full lg:w-[60%] xl:w-[50%]">
              <TextSkeleton lines={6} columns={2} lineWidth={70} />
            </div>
            {<MapSkeleton className="w-full lg:w-[40%] xl:w-[50%]" />}
          </div>
        </ReportSectionSkeleton>
      </AccordionSectionSpacer>
    </AccordionSkeleton>
  );
}
