export class ParsedAnnotation {
    completeLink: string;
    fileName: string;
    pageNumber?: number;
    pageNumberRangeStart: number;
    pageNumberRangeEnd?: number;
    annotationStub: string;
    parsed: boolean;
    // HACK: leave this here until we create the foreign key properly
    fileTitle?: string;
    section?: string;

    constructor(annotationInput: string, section: string = '', filename: string = '') {
        if (filename?.length > 0) {
            let re = /(.*?)-page-(\d+)-?(\d+)*\]?\]?/;
            let matches = annotationInput.match(re);
            if (matches == null)
                throw new Error("Annotation input does not match format");
            this.fileName = filename;
            this.completeLink = matches[0];
            this.annotationStub = matches[1];
            this.pageNumberRangeStart = Number(matches[2]);
            this.pageNumberRangeEnd = matches[3] ? Number(matches[3]) : undefined;
        } else {
            let re = /\[?\[?(.+\.pdf)\?annotation=(.*?)-page-(\d+)-?(\d+)*\]?\]?/;
            let matches = annotationInput.match(re);
            if (matches == null)
                throw new Error("Annotation input does not match format");

            this.completeLink = matches[0];
            this.fileName = matches[1];
            this.annotationStub = matches[2];
            this.pageNumberRangeStart = Number(matches[3]);
            this.pageNumberRangeEnd = matches[4] ? Number(matches[4]) : undefined;
        }

        this.parsed = true;
        this.section = section;
    }

    static compare(a: ParsedAnnotation, b: ParsedAnnotation): number {
        // First, compare by fileName
        if (a.fileName < b.fileName) return -1;
        if (a.fileName > b.fileName) return 1;

        // If fileName is the same, compare by pageNumberRangeStart
        if (a.pageNumberRangeStart < b.pageNumberRangeStart) return -1;
        if (a.pageNumberRangeStart > b.pageNumberRangeStart) return 1;

        // If pageNumberRangeStart is the same, compare by pageNumberRangeEnd
        if (a.pageNumberRangeEnd === undefined && b.pageNumberRangeEnd !== undefined) return -1;
        if (a.pageNumberRangeEnd !== undefined && b.pageNumberRangeEnd === undefined) return 1;
        if (a.pageNumberRangeEnd !== undefined && b.pageNumberRangeEnd !== undefined) {
            if (a.pageNumberRangeEnd < b.pageNumberRangeEnd) return -1;
            if (a.pageNumberRangeEnd > b.pageNumberRangeEnd) return 1;
        }


        // If everything is equal
        return 0;
    }
    getAllAnnotations(): string[] {
        let annotations: string[] = [];
        for (let i = this.pageNumberRangeStart; i <= (this.pageNumberRangeEnd ?? this.pageNumberRangeStart); i++) {
            annotations.push(this.annotationStub + "-page-" + i)
        }
        return annotations;
    }

    getFirstAnnotation(): string {
        return this.annotationStub + "-page-" + this.pageNumberRangeStart
    }
}
