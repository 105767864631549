import {
  ArrowSortUpRegular,
  ArrowClockwiseRegular,
} from "@fluentui/react-icons";
import { useAppSelector } from "../../../app/hooks";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../helpers/constants";
import HeroButton from "../../../components/ui/Buttons/HeroButton";

type Props = {
  address: string | undefined;
  coordinates: { lat: number; lng: number } | undefined;
};

export default function SubmitButton({ address, coordinates }: Props) {
  const location = useLocation();
  const isDemo = location.pathname.startsWith(ROUTES.demo);

  const { isLoading: isDemoLoading } = useAppSelector((state) => state.demo);

  const { isLoading: isResponseLoading, hasReportBeenGenerated } =
    useAppSelector((state) => state.addressSearch);

  const isLoading = isResponseLoading || (isDemo && isDemoLoading);

  return (
    <HeroButton
      type="submit"
      isLoading={isLoading}
      variant="hero"
      isDisabled={
        isLoading || !address || address?.length === 0 || !coordinates
      }
      label={hasReportBeenGenerated ? "Regenerate Report" : "Generate Report"}
      iconRight={{
        icon: hasReportBeenGenerated ? (
          <ArrowClockwiseRegular />
        ) : (
          <ArrowSortUpRegular />
        ),
      
      }}
      maxWidth={"full"}
    />
  );
}
