import React, {useEffect, useState} from 'react';
import {Alert20Filled, Check20Filled, Delete20Filled, Save20Filled} from "@fluentui/react-icons";
import {validateJsonFormat} from "./jsonValidation";

interface QueryItem {
  query: string;
  outputFormat: string;
  title?: string;
}

interface QueryInputProps {
  query: QueryItem,
  index: number,
  onChange: (index: number, field: keyof QueryItem, value: string) => void,
  onDelete: (index: number) => void,
  onSave?: (index: number) => void
}

export function QueryInput({query, index, onChange, onDelete, onSave}: QueryInputProps) {
  const [formatError, setFormatError] = useState<string>('');

  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
      try {
        setIsValid(!!(query?.outputFormat?.trim()) && validateJsonFormat(query?.outputFormat ?? ''));
      } catch (error) {
        setIsValid(false)
      }
    }
    , [query])

  const validateFormat = (value: string) => {
    try {
      if (value.trim()) {
        validateJsonFormat(value);
        setIsValid(true);
        setFormatError('');
      } else {
        setIsValid(false);
        setFormatError('');
      }
    } catch (error) {
      setIsValid(false);
      setFormatError(error instanceof Error ? error.message : 'Invalid JSON format');
    }

  };
  const handleFormatChange = (value: string) => {
    onChange(index, 'outputFormat', value);
    validateFormat(value);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md space-y-4 relative">
      <div className={"justify-between"}>
        <input value={query.title} placeholder={"Query Title"}
               onChange={(e) => onChange(index, 'title', e.target.value)} />
        <div className={"absolute top-4 right-4 grid grid-cols-2 gap-1"}>

          {onSave && (
            <button
              onClick={() => onSave(index)}
              className="text-gray-400 hover:text-red-500 transition-colors"
              aria-label="Delete query"
            >
              <Save20Filled/>
            </button>
          )
          }
          <button
            onClick={() => onDelete(index)}
            className="text-gray-400 hover:text-red-500 transition-colors"
            aria-label="Delete query"
          >
            <Delete20Filled/>
          </button>

        </div>
      </div>

      <div>
        <label htmlFor={`query-${index}`} className="block text-sm font-medium text-gray-700 mb-1">
          Query {index + 1}
        </label>
        <textarea
          id={`query-${index}`}
          value={query.query}
          onChange={(e) => onChange(index, 'query', e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Enter your question..."
          rows={3}
        />
      </div>
      <div>
        <div className="flex justify-between items-center mb-1">
          <label htmlFor={`format-${index}`} className="block text-sm font-medium text-gray-700">
            JSON Format
          </label>
          {query.outputFormat && (
            <div className="flex items-center gap-1">
              {isValid ? (
                <Check20Filled className="text-green-500"/>
              ) : (
                <Alert20Filled className="text-red-500"/>
              )}
              <span className={`text-xs ${isValid ? 'text-green-500' : 'text-red-500'}`}>
                {isValid ? 'Valid JSON' : 'Invalid JSON'}
              </span>
            </div>
          )}
        </div>
        <textarea
          id={`format-${index}`}
          value={query.outputFormat}
          onChange={(e) => handleFormatChange(e.target.value)}
          className={`w-full px-4 py-2 border rounded-md font-mono text-sm transition-colors
    ${formatError
            ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
            : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
          }`}
          placeholder="{'example': 'format'}"
          rows={3}
        />
        {formatError && (
          <p className="mt-1 text-sm text-red-500">{formatError}</p>
        )}
      </div>
    </div>
  );
}
