import React from "react";
import AccordionSkeleton from "../../../../components/ui/Loaders/AccordionSkeleton";
import TextSkeleton from "../../../../components/ui/Loaders/TextSkeleton";
import ReportSectionSkeleton from "../../../../components/ui/Loaders/ReportSectionSkeleton";
import { EntitlementInfo } from "../../helpers/demoSectionInfo";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";

export default function EntitlementSkeleton() {
  return (
    <AccordionSkeleton title={EntitlementInfo.title}>
      <AccordionSectionSpacer>
        <ReportSectionSkeleton title>
          <TextSkeleton
            dividers={false}
            lines={2}
            title
            lineWidth={100}
            instances={6}
          />
        </ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
      </AccordionSectionSpacer>
    </AccordionSkeleton>
  );
}
