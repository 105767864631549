import type { KeyValueContentType } from "../../../../../components/ui/KeyValueSection/KeyValueSection";

export const summaryData: KeyValueContentType = [
  {
    ["Building Permits"]: "4 to 50 weeks",
    ["Mechanical Permits"]: "0 to 8 weeks",
    ["Electrical Permits"]: "0 to 10 weeks",
  },
];

export const graphData = {
  bar_data_building_business_unit: {
    "Appointment Plan Check": '{"38":1,"121":1,"156":1}',
    "Expanded Counter Plan Check":
      '{"3":6,"7":5,"5":5,"9":5,"12":4,"6":4,"18":3,"29":3,"24":3,"43":3,"2":3,"1":2,"30":2,"50":2,"33":2,"17":2,"20":2,"10":2,"46":2,"56":2,"35":1,"41":1,"0":1,"78":1,"63":1,"73":1,"14":1,"44":1,"224":1,"116":1,"11":1,"54":1,"67":1,"8":1,"70":1,"15":1,"47":1,"4":1,"51":1,"36":1,"13":1,"31":1,"28":1,"150":1}',
    "Express Permit": '{"15":1}',
    "Plan Check at Counter":
      '{"1":26,"2":24,"0":23,"3":17,"4":13,"5":11,"7":10,"15":9,"17":8,"12":7,"6":6,"8":5,"16":5,"24":4,"19":4,"43":4,"18":4,"11":4,"10":4,"13":4,"21":4,"14":3,"39":3,"22":3,"77":3,"9":2,"42":2,"36":2,"46":2,"172":2,"117":2,"60":2,"35":2,"26":2,"83":1,"45":1,"189":1,"38":1,"52":1,"88":1,"158":1,"49":1,"90":1,"59":1,"96":1,"78":1,"55":1,"33":1,"181":1,"128":1,"31":1,"37":1,"66":1,"30":1,"56":1,"64":1,"40":1,"175":1,"146":1,"53":1,"122":1,"121":1,"70":1,"25":1,"50":1,"47":1,"57":1}',
    "Regular Plan Check":
      '{"36":13,"27":11,"78":9,"108":8,"11":8,"37":7,"8":7,"21":7,"17":6,"60":5,"88":5,"38":5,"25":5,"51":5,"34":5,"48":5,"54":5,"30":5,"12":4,"43":4,"29":4,"172":4,"61":4,"62":4,"18":3,"140":3,"15":3,"53":3,"23":3,"13":3,"33":3,"74":3,"79":3,"22":3,"45":3,"10":3,"31":3,"66":3,"44":3,"41":3,"162":3,"26":3,"28":3,"114":2,"40":2,"100":2,"86":2,"93":2,"129":2,"39":2,"0":2,"122":2,"119":2,"180":2,"3":2,"4":2,"9":2,"91":2,"218":2,"279":2,"57":2,"77":2,"85":2,"71":2,"115":2,"32":2,"49":2,"59":2,"154":1,"6":1,"5":1,"98":1,"7":1,"84":1,"197":1,"50":1,"206":1,"123":1,"19":1,"127":1,"97":1,"76":1,"90":1,"2":1,"46":1,"16":1,"112":1,"254":1,"64":1,"63":1,"124":1,"139":1,"67":1,"238":1,"42":1,"138":1,"233":1,"156":1,"167":1,"70":1,"52":1,"55":1}',
  },
  bar_data_building_permit_type: {
    "Bldg-Addition":
      '{"78":7,"30":4,"43":3,"17":3,"35":2,"44":2,"66":2,"79":2,"18":2,"45":2,"70":2,"29":2,"38":2,"26":2,"67":2,"22":2,"33":2,"172":1,"197":1,"39":1,"21":1,"28":1,"12":1,"139":1,"48":1,"124":1,"31":1,"56":1,"40":1,"88":1,"121":1,"51":1,"36":1,"74":1,"53":1,"112":1,"27":1,"57":1,"7":1,"91":1,"138":1,"60":1,"46":1,"8":1,"50":1,"1":1,"11":1,"25":1,"158":1,"55":1}',
    "Bldg-Alter/Repair":
      '{"2":25,"0":24,"1":22,"3":21,"7":13,"4":13,"15":13,"8":11,"17":10,"6":10,"5":10,"12":9,"27":8,"18":7,"37":7,"24":6,"13":6,"11":6,"10":6,"9":5,"36":5,"21":5,"38":4,"48":4,"30":4,"14":4,"25":3,"22":3,"62":3,"140":3,"19":3,"16":3,"29":3,"23":3,"33":2,"39":2,"43":2,"64":2,"20":2,"51":2,"31":2,"28":2,"63":2,"78":2,"218":2,"77":2,"122":2,"50":2,"119":2,"26":2,"32":2,"66":2,"45":2,"54":2,"114":2,"57":2,"90":2,"93":2,"86":2,"44":2,"56":2,"46":2,"34":1,"71":1,"154":1,"49":1,"233":1,"84":1,"98":1,"52":1,"206":1,"59":1,"116":1,"224":1,"181":1,"40":1,"41":1,"156":1,"91":1,"115":1,"42":1,"175":1,"53":1,"74":1,"238":1,"146":1,"47":1,"121":1,"35":1,"70":1,"76":1,"150":1,"167":1,"88":1,"100":1}',
    "Bldg-Demolition":
      '{"77":3,"16":3,"9":3,"11":3,"10":2,"117":2,"43":2,"39":2,"42":2,"13":2,"189":1,"12":1,"7":1,"1":1,"2":1,"31":1,"4":1,"37":1,"96":1,"5":1,"17":1,"88":1,"49":1,"83":1,"19":1,"0":1,"50":1,"47":1}',
    "Bldg-New":
      '{"36":8,"108":8,"60":3,"172":3,"34":3,"54":2,"21":2,"46":2,"129":2,"61":2,"17":2,"53":2,"85":2,"41":2,"51":2,"162":2,"279":2,"127":1,"123":1,"79":1,"62":1,"28":1,"10":1,"43":1,"59":1,"254":1,"115":1,"122":1,"31":1,"38":1,"97":1,"11":1,"156":1,"74":1,"52":1}',
    Grading:
      '{"12":3,"1":2,"5":2,"73":1,"9":1,"8":1,"49":1,"40":1,"180":1,"15":1,"6":1,"41":1,"128":1,"36":1,"162":1,"88":1,"33":1,"55":1,"29":1,"51":1,"54":1,"3":1,"172":1,"43":1,"59":1,"100":1,"78":1,"34":1,"4":1,"60":1,"0":1,"27":1}',
    "Nonbldg-Alter/Repair": '{"18":1}',
    "Nonbldg-New":
      '{"3":2,"60":2,"5":2,"21":2,"33":1,"88":1,"172":1,"180":1,"29":1,"25":1,"27":1}',
    Sign: '{"7":1,"4":1,"2":1}',
    "Swimming-Pool/Spa":
      '{"43":2,"61":2,"11":2,"5":2,"1":2,"26":1,"21":1,"2":1,"71":1,"22":1,"54":1,"36":1,"78":1,"3":1,"12":1,"88":1,"24":1,"25":1,"19":1}',
  },
  bar_data_electrical_business_unit: {
    "Plan Check at Counter":
      '{"0":9,"1":3,"7":2,"2":2,"3":2,"5":2,"8":1,"4":1,"17":1,"9":1,"10":1,"6":1,"33":1}',
    "Regular Plan Check":
      '{"4":3,"8":3,"2":2,"6":2,"19":2,"14":2,"22":2,"34":1,"1":1,"146":1,"23":1,"7":1,"11":1,"27":1,"41":1,"9":1,"15":1,"0":1,"5":1}',
  },
  bar_data_electrical_permit_type: {
    Electrical:
      '{"0":10,"4":4,"2":4,"8":4,"1":4,"7":3,"6":3,"5":3,"3":2,"19":2,"14":2,"9":2,"22":2,"34":1,"10":1,"146":1,"23":1,"17":1,"11":1,"41":1,"27":1,"15":1,"33":1}',
  },
  bar_data_mechanical_business_unit: {
    "Plan Check at Counter":
      '{"0":28,"1":13,"3":8,"2":8,"10":5,"22":5,"11":5,"4":3,"6":3,"23":2,"48":2,"15":1,"13":1,"7":1,"19":1}',
    "Regular Plan Check":
      '{"1":12,"2":7,"0":6,"3":5,"10":3,"27":3,"8":2,"115":2,"4":2,"34":2,"144":1,"5":1,"145":1,"76":1,"23":1,"15":1,"28":1,"14":1,"13":1}',
  },
  bar_data_mechanical_permit_type: {
    Elevator:
      '{"1":10,"0":6,"2":5,"10":3,"115":2,"3":2,"8":1,"76":1,"15":1,"5":1,"28":1,"27":1}',
    "Fire Sprinkler":
      '{"0":23,"1":14,"3":9,"2":8,"10":5,"22":5,"23":3,"11":2,"4":2,"13":2,"6":1,"48":1,"14":1,"8":1,"7":1,"19":1}',
    HVAC: '{"11":2,"144":1,"6":1,"34":1,"27":1}',
    Plumbing:
      '{"0":5,"4":3,"3":2,"2":2,"6":1,"11":1,"1":1,"15":1,"34":1,"27":1,"145":1,"48":1}',
  },
  box_data_building_business_unit: {
    "Appointment Plan Check": {
      max: 156.42857142857142,
      median: 121,
      min: 38.285714285714285,
      q1: 79.64285714285714,
      q3: 138.71428571428572,
    },
    "Expanded Counter Plan Check": {
      max: 224.85714285714286,
      median: 15,
      min: 0,
      q1: 6.7142857142857135,
      q3: 38.78571428571429,
    },
    "Express Permit": {
      max: 15.428571428571429,
      median: 15.428571428571429,
      min: 15.428571428571429,
      q1: 15.428571428571429,
      q3: 15.428571428571429,
    },
    "Plan Check at Counter": {
      max: 189,
      median: 4.428571428571429,
      min: 0,
      q1: 0.7142857142857143,
      q3: 17.857142857142858,
    },
    "Regular Plan Check": {
      max: 279.2857142857143,
      median: 43,
      min: 0.7142857142857143,
      q1: 26.357142857142858,
      q3: 78.67857142857143,
    },
  },
  box_data_building_permit_type: {
    "Bldg-Addition": {
      max: 197.85714285714286,
      median: 44.285714285714285,
      min: 1.8571428571428572,
      q1: 29.535714285714285,
      q3: 75.14285714285714,
    },
    "Bldg-Alter/Repair": {
      max: 238.28571428571428,
      median: 10.642857142857142,
      min: 0,
      q1: 2,
      q3: 31.964285714285715,
    },
    "Bldg-Demolition": {
      max: 189,
      median: 17,
      min: 0,
      q1: 10.357142857142858,
      q3: 48.785714285714285,
    },
    "Bldg-New": {
      max: 279.2857142857143,
      median: 59.57142857142857,
      min: 0,
      q1: 36.857142857142854,
      q3: 108.57142857142857,
    },
    Grading: {
      max: 180.71428571428572,
      median: 34.285714285714285,
      min: 0,
      q1: 8.857142857142858,
      q3: 59.57142857142857,
    },
    "Nonbldg-Alter/Repair": {
      max: 18.142857142857142,
      median: 18.142857142857142,
      min: 18.142857142857142,
      q1: 18.142857142857142,
      q3: 18.142857142857142,
    },
    "Nonbldg-New": {
      max: 180.71428571428572,
      median: 21.428571428571427,
      min: 0,
      q1: 3,
      q3: 46.92857142857143,
    },
    Sign: {
      max: 7.428571428571429,
      median: 4.857142857142857,
      min: 2.2857142857142856,
      q1: 3.571428571428571,
      q3: 6.142857142857142,
    },
    "Swimming-Pool/Spa": {
      max: 88.14285714285714,
      median: 20.285714285714285,
      min: 0,
      q1: 2.7857142857142856,
      q3: 43.214285714285715,
    },
  },
  box_data_electrical_business_unit: {
    "Plan Check at Counter": {
      max: 33,
      median: 0.7142857142857143,
      min: 0,
      q1: 0,
      q3: 4.464285714285714,
    },
    "Regular Plan Check": {
      max: 146.71428571428572,
      median: 8.928571428571429,
      min: 0.8571428571428571,
      q1: 5.392857142857143,
      q3: 20.214285714285715,
    },
  },
  box_data_electrical_permit_type: {
    Electrical: {
      max: 146.71428571428572,
      median: 4,
      min: 0,
      q1: 0.42857142857142855,
      q3: 9.142857142857142,
    },
  },
  box_data_mechanical_business_unit: {
    "Plan Check at Counter": {
      max: 48.42857142857143,
      median: 1,
      min: 0,
      q1: 0.07142857142857142,
      q3: 3.928571428571429,
    },
    "Regular Plan Check": {
      max: 145.42857142857142,
      median: 3,
      min: 0.5714285714285714,
      q1: 1.8571428571428572,
      q3: 14.571428571428571,
    },
  },
  box_data_mechanical_permit_type: {
    Elevator: {
      max: 115.57142857142857,
      median: 2,
      min: 0,
      q1: 1.2857142857142858,
      q3: 9.714285714285715,
    },
    "Fire Sprinkler": {
      max: 48.285714285714285,
      median: 1,
      min: 0,
      q1: 0,
      q3: 3.6428571428571432,
    },
    HVAC: {
      max: 144.71428571428572,
      median: 19.5,
      min: 6.857142857142857,
      q1: 11.214285714285715,
      q3: 32.82142857142857,
    },
    Plumbing: {
      max: 145.42857142857142,
      median: 3.857142857142857,
      min: 0.14285714285714285,
      q1: 1.0714285714285714,
      q3: 12.142857142857142,
    },
  },
  building_median: 18.92857142857143,
  building_n: 702,
  building_q1: 4.035714285714286,
  building_q3: 49.642857142857146,
  median_building: 18.92857142857143,
  median_electrical: 4,
  median_mechanical: 1.8571428571428572,
  zone_filter: "RD1.5",
};
