import { useState } from "react";
import ThreeColumnLayout from "../../layouts/ThreeColumnLayout";
import Accordion from "../../components/ui/Accordion/Accordion";
import UserSettingsForm from "./components/UserSettingsForm";

export default function Account() {
  const [setup, setSetup] = useState(false);

  return (
    <ThreeColumnLayout className="defaultPageStyle">
      <Accordion
        collapsible={false}
        className="mx-auto max-w-[600px]"
        title={setup ? "Create Your Account" : "Account Settings"}
      >
        <div className="mb-3">
          <UserSettingsForm setSetup={setSetup} />
        </div>
      </Accordion>
    </ThreeColumnLayout>
  );
}
