import React, { useState, useRef } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import { MoreHorizontal24Regular } from "@fluentui/react-icons";

export type DropdownOption =
  | {
      type: "option";
      label: string;
      onClick: () => void;
      disabled?: boolean;
      hidden?: boolean;
      icon?: JSX.Element;
    }
  | {
      type: "header";
      label: string;
      hidden?: boolean;
    }
  | {
      type: "divider";
      hidden?: boolean;
    };

type DropdownProps = {
  trigger: JSX.Element;
  options: DropdownOption[];
};

const DropdownMenu = ({ trigger, options }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  const defaultToggle = (
    <MoreHorizontal24Regular className="text-rf-very-dark-gray" />
  );

  const handleOptionClick = (onClick: () => void) => {
    setIsOpen(false);
    onClick();
  };

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {trigger ? trigger : defaultToggle}
      </button>

      {isOpen && (
        <div
          className="absolute right-0 z-40 mt-2 max-w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="p-1">
            {options.map((option, index) => {
              switch (option.type) {
                case "option":
                  return option.hidden ? (
                    <></>
                  ) : (
                    <button
                      disabled={option.disabled}
                      key={index}
                      onClick={() => handleOptionClick(option.onClick)}
                      className="flex w-full items-center gap-2 rounded-md px-3 py-1.5 duration-300 hover:bg-rf-very-light-gray active:bg-rf-light-gray disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent disabled:active:bg-transparent"
                      role="menuitem"
                    >
                      {option.icon && option.icon}
                      <p className="desc-font line-clamp-1 text-nowrap text-left text-rf-very-dark-gray">
                        {option.label}
                      </p>
                    </button>
                  );
                case "header":
                  return option.hidden ? (
                    <></>
                  ) : (
                    <div
                      key={index}
                      className="desc-font line-clamp-1 text-nowrap px-3 py-1.5 font-[500] text-rf-very-dark-gray"
                      role="presentation"
                    >
                      {option.label}
                    </div>
                  );
                case "divider":
                  return option.hidden ? (
                    <></>
                  ) : (
                    <hr key={index} className="line-break mx-3 my-1" />
                  );
                default:
                  return null;
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
