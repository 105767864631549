export const renderParagraphs = (data: string[] | string) => {
  if (typeof data === "string") {
    return <p className="body-font">{data}</p>;
  }

  return (
    <>
      {data.map((p, i) => (
        <p className="body-font" key={i}>
          {p}
        </p>
      ))}
    </>
  );
};

export type SectionType = {
  title?: string;
  sections: {
    title: string;
    paragraphs?: string[];
    list?: {
      type: "ordered" | "unordered";
      items: string[];
    };
  }[];
};

export const renderSections = ({ title, sections }: SectionType) => {
  return (
    <div>
      {title && <p className="body-font mb-5 font-bold">{title}</p>}
      {sections.map(({ title, paragraphs, list }, index) => (
        <div key={index} className="mb-5">
          <p className="body-font font-bold">{title}</p>

          {/* Render paragraphs */}
          {paragraphs &&
            paragraphs.map((paragraph, i) => {
              return <p key={i}>{paragraph}</p>;
            })}

          {/* Render lists */}
          {list &&
            (list.type === "ordered" ? (
              <ol className="ml-5 list-decimal">
                {list.items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ol>
            ) : (
              <ul className="ml-5 list-disc">
                {list.items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            ))}
        </div>
      ))}
    </div>
  );
};
