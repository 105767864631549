import { SectionType } from "../../../helpers/renderers";

export const summaryOfPermittedUsesData: SectionType = {
  title: "Permitted Uses in Past Developments:",
  sections: [
    {
      title: "1920s-1950s:",
      paragraphs: [
        "Single-Family Home (Primary Use).",
        "Aligned with early low-density residential zoning.",
      ],
    },

    {
      title: "1960s-1980s:",
      paragraphs: [
        "Duplex Conversion.",
        "Permitted under RD zoning, increasing density while maintaining residential character.",
      ],
    },

    {
      title: "1990s-Present:",
      paragraphs: [
        "Multi-Family Residential (3 Units).",
        "Developed under RD1.5 density standards, utilizing the 1 unit per 1,500 sq. ft. allowance.",
      ],
    },

    {
      title: "ADU Addition (2021):",
      paragraphs: [
        "Small accessory dwelling added to maximize site use, compliant with recent ADU regulations.",
      ],
    },

    {
      title: "Industrial Site Use (ISTE) History:",
      paragraphs: [
        "No Industrial Use on Record: Historical land use records show continuous residential use. No industrial or commercial operations have occurred, minimizing the likelihood of legacy contamination or hazardous material issues.",
      ],
    },

    {
      title: "Conclusion:",
      paragraphs: [
        "The parcel’s development history aligns with permitted uses under RD1.5 zoning. No industrial history simplifies environmental compliance and supports continued residential development.",
      ],
    },
  ],
};

export const zoningDesignationHistoryData: SectionType = {
  title: "Historical Zoning Timeline:",
  sections: [
    {
      title: "1920s-1940s:",
      paragraphs: [
        "Zoning: R-1 (Single-Family Residential).",
        "Use: Limited to single-family homes, reflecting early low-density residential planning in Venice.",
      ],
    },

    {
      title: "1950s-1970s:",
      paragraphs: [
        "Zoning: R2 (Two-Family Residential).",
        "Use: Allowed duplexes in response to post-war housing demand.",
      ],
    },
  ],
};

export const covenantsAndAgreementsData: SectionType = {
  title: "Recorded Covenants and Agreements:",
  sections: [
    {
      title: "Height Restriction Covenant (1995):",
      paragraphs: [
        "Details: Limits building height to 35 feet, stricter than standard RD1.5 regulations.",
        "Purpose: To preserve neighborhood character and protect coastal view corridors.",
      ],
    },

    {
      title: "Parking Agreement (2005):",
      paragraphs: [
        "Details: Requires the provision of 2 off-street parking spaces per unit.",
        "Purpose: Mitigates parking demand in a high-traffic area.",
      ],
    },

    {
      title: "Landscape Maintenance Covenant (2010):",
      paragraphs: [
        "Details: Mandates regular upkeep of front-yard landscaping.",
        "Purpose: Ensures aesthetic consistency and compliance with local beautification efforts.",
      ],
    },

    {
      title: "Oil Drilling Prohibition (Overlay Condition, 2015):",
      paragraphs: [
        "Details: Prohibits oil drilling activities on the parcel, despite the “O” zoning overlay.",
        "Purpose: Aligns with city and community goals to limit oil extraction in residential areas.",
      ],
    },

    {
      title: "Conclusion:",
      paragraphs: [
        "The parcel is subject to covenants aimed at preserving neighborhood aesthetics, managing parking, and ensuring responsible land use. These agreements may impact future development plans and require compliance for ongoing use.",
      ],
    },
  ],
};

export const liensData: SectionType = {
  title: "Recorded Liens:",
  sections: [
    {
      title: "Mechanic’s Lien (2018):",
      paragraphs: [
        "Filed By: Local contractor.",
        "Amount: $15,000.",
        "Reason: Non-payment for completed renovation work (kitchen remodel).",
        "Status: Resolved and released in 2019 after payment was made.",
      ],
    },

    {
      title: "Tax Lien (2021):",
      paragraphs: [
        "Filed By: Los Angeles County Treasurer.",
        "Amount: $5,000.",
        "Reason: Unpaid property taxes.",
        "Status: Cleared in 2022 after back taxes were settled.",
      ],
    },

    {
      title: "Notice of Pending Lien (2023):",
      paragraphs: [
        "Filed By: City of Los Angeles.",
        "Amount: $2,500.",
        "Reason: Code enforcement violation related to unpermitted fence construction.",
        "Status: Pending; owner must resolve permit issue to avoid formal lien.",
      ],
    },

    {
      title: "Conclusion:",
      paragraphs: [
        "The parcel has a history of liens, with most resolved. However, a pending lien related to code enforcement could affect the property’s title and should be addressed promptly.",
      ],
    },
  ],
};
