import { SectionType } from "../../../helpers/renderers";

export const environmentalFlagsOnPastUsesAndHistoryData: SectionType = {
  title: "Historical Uses",
  sections: [
    {
      title: "1920s-1950s",
      paragraphs: [
        "Use: Single-family residence.",
        "Impact on Environmental Restrictions: Minimal. No industrial or high-impact use recorded during this period.",
      ],
    },
    {
      title: "1960s-1980s",
      paragraphs: [
        "Use: Multi-family residential (converted to duplex).",
        "Impact: Increased density but no environmental concerns noted. Continued residential use aligns with current zoning and does not trigger additional environmental review.",
      ],
    },
    {
      title: "1990s-2010s",
      paragraphs: [
        "Use: Mixed short-term and long-term rental property.",
        "Impact: Potential for higher foot traffic and localized wear, but no recorded environmental violations.",
      ],
    },
    {
      title: "Present",
      paragraphs: [
        "Use: Owner-occupied with periodic short-term rental.",
        "Impact: No additional environmental restrictions tied to historical use, aside from general compliance with current flood and coastal zone regulations.",
      ],
    },
    {
      title: "Key Considerations",
      paragraphs: [
        "No Historical Industrial or Commercial Use: No indication of prior activities (e.g., manufacturing, dry cleaning, or gas station) that might necessitate further environmental review.",
        "Residential Continuity: Past uses remain consistent with zoning and environmental expectations, posing no added burden under current regulations.",
      ],
    },
    {
      title: "Conclusion",
      paragraphs: [
        "The parcel’s historical residential uses have minimal impact on current environmental restrictions.",
        "No legacy contamination or environmental hazards are likely to affect future development.",
      ],
    },
  ],
};

export const environmentalRestrictionsData: SectionType = {
  title: "Key Environmental Restrictions",
  sections: [
    {
      title: "Coastal Zone Regulations",
      paragraphs: [
        "Impact: Parcel falls within the California Coastal Zone, requiring review by the California Coastal Commission (CCC) for any new development or significant alterations.",
        "Implications: Extended permitting timelines; additional environmental impact considerations.",
      ],
    },
    {
      title: "Flood Zone Designation (Zone AE)",
      paragraphs: [
        "Impact: FEMA maps designate this parcel in a flood-prone area.",
        "Implications: Development must comply with specific elevation and floodproofing standards; may increase construction costs and require flood insurance.",
      ],
    },
    {
      title: "Protected Tree Ordinance",
      paragraphs: [
        "Impact: Existing mature trees may be subject to preservation requirements.",
        "Implications: Potential limits on site clearance; may need arborist evaluation and permits for removal.",
      ],
    },
    {
      title: "Air Quality Management District (AQMD) Compliance",
      paragraphs: [
        "Impact: Area regulated for emissions due to proximity to major transportation corridors.",
        "Implications: Construction activities subject to AQMD dust and equipment emissions standards; may require mitigation measures.",
      ],
    },
    {
      title: "Development Impacts",
      paragraphs: [
        "Permitting Delays: Additional layers of review will extend the project timeline.",
        "Increased Costs: Compliance with flood and coastal zone requirements may elevate design and construction expenses.",
        "Design Constraints: Restrictions on site layout and materials to accommodate environmental standards.",
      ],
    },
    {
      title: "Conclusion",
      paragraphs: [
        "Development is feasible but will require careful planning to navigate environmental regulations and mitigate cost and timeline impacts.",
      ],
    },
  ],
};

export const hazardousMaterialsData: SectionType = {
  sections: [
    {
      title: "Hazardous Materials History:",
      paragraphs: [
        "No Recorded Incidents: A review of historical records indicates no previous hazardous materials spills, storage violations, or contamination events associated with this parcel.",
      ],
    },
    {
      title: "Environmental Reports:",
    },
    {
      title: "Phase I Environmental Site Assessment (2015):",
      paragraphs: [
        "Scope: Standard ASTM assessment, including historical use review and site inspection.",
        "Findings: No recognized environmental conditions (RECs).",
      ],
    },
    {
      title: "Soil Screening Report (2016):",
      paragraphs: [
        "Scope: Limited soil testing in preparation for landscaping upgrades.",
        "Findings: No detectable levels of hazardous substances.",
      ],
    },
    {
      title:
        "City of Los Angeles Fire Department (LAFD) Hazardous Materials Division Check (2020):",
      paragraphs: [
        "Scope: Verification of compliance with hazardous material handling regulations.",
        "Findings: No hazardous materials permits required for this parcel; no violations on record.",
      ],
    },
    {
      title: "Conclusion:",
      paragraphs: [
        "Scope: Verification of compliance with hazardous material handling regulations.",
        "The parcel has no history of hazardous materials presence or incidents, supported by multiple environmental and regulatory clearances.",
      ],
    },
  ],
};

export const pastViolationsData: SectionType = {
  title: "Notable Violations:",
  sections: [
    {
      title: "Unpermitted Construction (2019):",
      paragraphs: [
        "Violation: Addition of a second-story deck without proper permits.",
        "Resolution: Required retroactive permit and structural inspection; resolved in 2020.",
      ],
    },
    {
      title: "Zoning Use Violation (2021):",
      paragraphs: [
        "Violation: Property listed as a short-term rental in an area with restricted STR regulations.",
        "Resolution: Ceased STR operations following a City enforcement action; owner paid fines.",
      ],
    },
    {
      title: "Overgrown Vegetation Complaint (2023):",
      paragraphs: [
        "Violation: Vegetation encroaching onto the public sidewalk, creating a hazard.",
        "Resolution: Cleared within 30 days; no fines issued.",
      ],
    },
    {
      title: "Key Flags:",
      paragraphs: [
        "History of non-compliance with construction and zoning regulations.",
        "Potential for stricter scrutiny on future permitting applications.",
      ],
    },
  ],
};

export const surroundingLotAnalysisData: SectionType = {
  title: "Surrounding Parcels:",
  sections: [
    {
      title: "North:",
      paragraphs: [
        "Address: 501 Main St.",
        "Zoning: C2-1 (Commercial).",
        "Use: Retail and Office.",
        "Flag: Potential noise and traffic impacts due to commercial use.",
      ],
    },
    {
      title: "South:",
      paragraphs: [
        "Address: 123 Pacific Ave.",
        "Zoning: R3-1.",
        "Use: Multi-family Residential.",
        "Flag: None.",
      ],
    },
    {
      title: "East:",
      paragraphs: [
        "Address: 402 Ocean Walk.",
        "Zoning: R3-1.",
        "Use: Multi-family with short-term rentals (Airbnb).",
        "Flag: Potential for high turnover and increased parking demand.",
      ],
    },
    {
      title: "West:",
      paragraphs: [
        "Address: 330 Ocean Front Walk.",
        "Zoning: OS (Open Space).",
        "Use: Public Beach Access and Boardwalk.",
        "Flag: High pedestrian activity, potential for restricted access during events.",
      ],
    },
    {
      title: "Key Flags:",
      paragraphs: [
        "Commercial zoning to the north may affect residential character.",
        "East-side short-term rentals could increase parking competition.",
        "Beach and Boardwalk proximity may result in noise and heavy foot traffic.",
      ],
    },
  ],
};

export const userDocumentAbstractPhaseOneData: SectionType = {
  title: "Phase 1 Environmental Site Assessment (ESA) Summary",
  sections: [
    {
      title: "Scope of Work",
      paragraphs: [
        "The Phase 1 ESA was conducted in accordance with the ASTM E1527-21 standard and included the following components:",
      ],
      list: {
        type: "unordered",
        items: [
          "A site visit to visually inspect the property for signs of contamination or hazardous materials.",
          "A review of historical land use records (e.g., aerial photographs, city directories, and land title records).",
          "Examination of regulatory databases for known or suspected environmental concerns.",
          "Interviews with current and past property owners, tenants, and local officials.",
        ],
      },
    },
    {
      title: "Site Description",
      paragraphs: [
        "The property consists of [describe site size, location, and surrounding land uses]. The site is currently used for [current use, e.g., commercial/industrial/residential]. Historical use of the property includes [insert brief history, including past industrial or commercial uses if applicable].",
      ],
    },
    {
      title: "Findings",
      paragraphs: [
        "The following key findings were identified during the assessment:",
      ],
      list: {
        type: "ordered",
        items: [
          "Historical Uses: The property was historically used as [describe historical uses], which may have involved the use of hazardous materials.",
          "Recognized Environmental Conditions (RECs): [Detail any identified RECs, such as nearby industrial facilities, underground storage tanks, or chemical spills].",
          "Adjacent Properties: The surrounding properties include [describe surrounding properties], and some may pose a potential environmental concern due to [list reasons, such as proximity to gas stations, dry cleaners, etc.].",
          "No Obvious Signs of Contamination: During the site visit, no visible signs of environmental contamination, such as stained soil, surface debris, or leaking tanks, were observed.",
        ],
      },
    },
    {
      title: "Conclusions",
      paragraphs: [
        "Based on the data collected, the following conclusions have been drawn:",
      ],
      list: {
        type: "unordered",
        items: [
          "Recognized Environmental Conditions (RECs): [Summarize any RECs and their potential risks].",
          "Further Investigation: It is recommended that a Phase 2 ESA be conducted to evaluate potential contamination if [specific conditions, such as subsurface investigations or soil testing] are warranted.",
        ],
      },
    },
    {
      title: "Recommendations",
      list: {
        type: "unordered",
        items: [
          "Phase 2 ESA: Conduct further subsurface investigation if the RECs identified warrant additional review.",
          "No Further Action: If no RECs were identified or deemed insignificant, the site is recommended for redevelopment without further investigation.",
        ],
      },
    },
    {
      title: "Limitations",
      paragraphs: [
        "This Phase 1 ESA was limited by [insert any limitations such as access restrictions, incomplete records, etc.].",
      ],
    },
  ],
};

export const userDocumentAbstractPhaseTwoData: SectionType = {
  title: "Report Summary:",
  sections: [
    {
      title: "Conducted: 2022",
      paragraphs: [
        "Scope: Subsurface investigation, including soil and groundwater sampling.",
      ],
    },
    {
      title: "Findings:",
      paragraphs: [
        "No detectable contaminants in soil or groundwater.",
        "No evidence of underground storage tanks (USTs) or hazardous material residues.",
        "Confirmed clean site conditions.",
      ],
    },
    {
      title: "Development Impacts:",
      paragraphs: [
        "No Remediation Required: Site is clear for construction with no further environmental investigations necessary.",
        "Permitting Streamlined: No additional environmental mitigation measures expected, reducing potential delays and costs.",
      ],
    },
    {
      title: "Conclusion:",
      paragraphs: [
        "The Phase II report confirms that environmental conditions pose no barriers to development.",
      ],
    },
  ],
};
