import {useMemo, useState} from "react";
import Accordion from "../../../../components/ui/Accordion/Accordion";
import { SavedSearchesInfo } from "../../helpers/demoSectionInfo";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";
import { useAppSelector } from "../../../../app/hooks";
import SavedSearchesSkeleton from "./SavedSearchesSkeleton";
import { dataArray } from "./data/data";
import SavedSection from "./SavedSection";
import {parseAnswerToHtml} from "../../../../components/Answer/AnswerParser";
import {answerToElements} from "../../../AnswerToUserQuestion/util/AnswerToElements";

export default function SavedSearches() {
  const { isLoading } = useAppSelector((state) => state.demo);
  const [selectedFilter, setSelectedFilter] = useState<string>("all");

  const filterOptions = [
    { label: "Searches performed on All Data", value: "all" },
    { label: "Searches performed on Public Data only", value: "public" },
    { label: "Searches performed on My Secure Data only", value: "myData" },
  ];

  const filteredDataArray = dataArray
    .map(item => {
      const {parsedAnswerElements, sources, additionalSources} =
        answerToElements(isLoading, item.answer, () => {});
      return item;
    })

    .filter((item) => {
      if (selectedFilter === "all") return true; // Show all data
      if (selectedFilter === "myData") return item.userData === true; // Show userData: true
      if (selectedFilter === "public") return item.userData === false; // Show userData: false
    return true; // Fallback to show all data
  });

  if (isLoading) return <SavedSearchesSkeleton />;

  return (
    <Accordion 
      childPadding={false}
      title={SavedSearchesInfo.title}
      id={SavedSearchesInfo.id}
    >
      {/* Filter Section */}
      <div className="flex justify-between gap-4 p-5 shadow-[0px_0px_20px_0px_#00000040] sm:justify-start sm:gap-40">
        <h2 className="subheader-font font-bold">Filter results</h2>
        <div className="flex flex-col gap-0.5">
          {filterOptions.map(({ label, value }) => (
            <div key={value} className="flex items-center">
              <input
                type="radio"
                name="filter"
                value={value}
                checked={selectedFilter === value}
                onChange={() => setSelectedFilter(value)}
                className={`my-[0.5px] block h-5 w-5 cursor-pointer select-none rounded-md text-rf-vibrant-red duration-150 hover:border-rf-vibrant-red focus:border-rf-vibrant-red focus:ring-rf-vibrant-red sm:my-[1.25px] md:my-[2px]`}
              />
              <label
                className={`desc-font line-clamp-3 grow cursor-pointer select-none pl-2.5`}
                onClick={() => setSelectedFilter(value)}
              >
                {label}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Saved Searches */}
      <div className="p-1.5 sm:p-6">
        <AccordionSectionSpacer>
          {filteredDataArray.map((sectionData, i) => (
            <SavedSection key={i} data={sectionData} />
          ))}
        </AccordionSectionSpacer>
      </div>
    </Accordion>
  );
}
