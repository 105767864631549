import React from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
};

export default function MapSkeleton({ className }: Props) {
  return (
    <div
      className={twMerge(
        "h-[350px] w-full animate-pulse rounded-[10px] bg-rf-light-gray",
        className,
      )}
    ></div>
  );
}
