type Props = {
  title?: boolean;
  secondaryTitle?: boolean;
  children?: React.ReactNode;
};

export default function ReportSectionSkeleton({
  title,
  children,
  secondaryTitle,
}: Props) {
  return (
    <div role="status">
      <div
        className={`flex h-[30px] w-full items-center justify-between gap-3 rounded-md bg-rf-very-light-gray px-3 text-left`}
      >
        {title && (
          <div className="h-3 w-[120px] rounded-full bg-rf-light-beige md:h-4 md:w-[180px]"></div>
        )}

        {secondaryTitle && (
          <div className="h-3 w-[120px] rounded-full bg-rf-light-beige md:h-4 md:w-[180px]"></div>
        )}
      </div>
      {children && (
        <div className={"p-1.5 sm:p-6 sm:pt-3"}>
          <div
            className={
              "thin-scrollbar max-h-[800px] overflow-y-auto sm:max-h-fit sm:overflow-hidden"
            }
          >
            {children}
          </div>
        </div>
      )}{" "}
      <span className="sr-only">Loading...</span>
    </div>
  );
}
