import React from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  title?: boolean;
  lines?: number; // min - 1, max - 20
  instances?: number; // min - 1, max - 10
  columns?: number; // min - 1, max - 4
  lineWidth?: number; // min - 10%, max - 100%
  dividers?: boolean;
};

export default function TextSkeleton({
  lines = 0,
  title,
  instances = 1,
  columns = 1,
  lineWidth = 100,
  dividers = true,
}: Props) {
  const lineWidthPerc = Math.max(10, Math.min(lineWidth, 100));
  const columnsQuantity = Math.max(1, Math.min(columns, 4));
  const instancesAmount = Math.max(1, Math.min(instances, 10));
  const linesAmount = Math.min(lines, 20);

  const instanceQuantity = Array(instancesAmount)
    .fill(0)
    .map((_, i) => i);

  const lineQuantity = Array(linesAmount)
    .fill(0)
    .map((_, i) => i);

  const paragraph = (
    <div>
      {title && (
        <div
          className={`${linesAmount > 0 && "mb-3"} h-3 w-1/2 rounded-full bg-rf-light-gray md:h-4 md:w-1/3`}
        ></div>
      )}
      {lineQuantity.map((_, i) => (
        <div
          key={i}
          style={{ width: `${lineWidthPerc}%` }}
          className={` ${i === 0 && "mt-0"} ${i + 1 === linesAmount && "mb-0"} my-3 h-2.5 rounded-full bg-rf-light-gray md:h-3`}
        ></div>
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  );

  const body = instanceQuantity.map((_, i) => (
    <div key={i}>
      {paragraph}
      {instancesAmount > 1 && i + 1 !== instancesAmount && (
        <hr className={twMerge("line-break", !dividers && "opacity-0")} />
      )}
    </div>
  ));

  if (!title && linesAmount == 0) return <></>;

  return (
    <div role="status" className="w-full animate-pulse">
      {/* Flexbox for columns */}
      <div className="flex flex-wrap gap-6">
        {Array(columnsQuantity)
          .fill(0)
          .map((_, colIndex) => (
            <div key={colIndex} className="min-w-[calc(100%/columns)] flex-1">
              {body}
            </div>
          ))}
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
}
