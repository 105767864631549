import React, { useState } from "react";
import Accordion from "../../../../components/ui/Accordion/Accordion";
import { SupplementarySourcesViewerInfo } from "../../helpers/demoSectionInfo";
import { MotionsContent } from "../../../RelevantMotions/components/MotionsContent";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";
import { useAppSelector } from "../../../../app/hooks";
import SupplementarySourcesViewerSkeleton from "./SupplementarySourcesViewerSkeleton";
import { cityCouncilMinutesData, listItems, listColData } from "./data/data";
import ListDetails from "../../../../components/ui/ListDetails/ListDetails";
import { ListSectionType } from "../../../../components/ui/ListDetails/ListDetails";
import PreviewCard from "./PreviewCard";
import { categoryIconMap } from "../../../RelevantMotions/components/MotionsCommon";

const labelCase = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export default function SupplementarySourcesViewer() {
  const [selectListItem, setSelectListItem] = useState(listItems[0]);
  const { isLoading } = useAppSelector((state) => state.demo);

  const listSectionBills: ListSectionType = {
    items: listItems.map((item) => ({
      desc: item,
      onClick: () => setSelectListItem(item),
    })),
  };

  const listSectionCouncilMinutes: ListSectionType = {
    items: Array.from(categoryIconMap).map(([name]) => {
      return {
        desc: labelCase(name),
        counter: `${cityCouncilMinutesData?.length} document${cityCouncilMinutesData?.length === 1 ? "" : "s"} available`,
      };
    }),
  };

  const selectedData =
    selectListItem === "State Bill" ? listColData.state : listColData.assembly;

  if (isLoading) return <SupplementarySourcesViewerSkeleton />;

  return (
    <Accordion
      title={SupplementarySourcesViewerInfo.title}
      id={SupplementarySourcesViewerInfo.id}
    >
      <AccordionSectionSpacer>
        {/* City Council Minutes */}

        <ListDetails
          title="City Council Minutes"
          collapsible
          list={[listSectionCouncilMinutes]}
          isInitiallyOpen={false}
        >
          <div className="mt-2.5 flex flex-col gap-2.5">
            {cityCouncilMinutesData.map((item, i) => (
              <PreviewCard
                title={item.title}
                description={item.description}
                url={item.url}
                key={i}
              />
            ))}
          </div>
        </ListDetails>

        {/* State and Assembly Bills */}
        <ListDetails
          title="State and Assembly Bills"
          collapsible
          list={[listSectionBills]}
          isInitiallyOpen={false}
        >
          <div className="mt-2.5 flex flex-col gap-2.5">
            {selectedData.map((item, i) => (
              <PreviewCard
                description={item.description}
                title={item.title}
                url={item.url}
                key={i}
              />
            ))}
          </div>
        </ListDetails>

        <MotionsContent collapsible={true} />
      </AccordionSectionSpacer>
    </Accordion>
  );
}
