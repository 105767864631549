import React from "react";
import Accordion from "../../../../components/ui/Accordion/Accordion";
import { EnvironmentalInfo } from "../../helpers/demoSectionInfo";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";
import ReportSection from "../../../../components/ui/ReportSection/ReportSection";
import { renderSections } from "../../helpers/renderers";
import {
  environmentalFlagsOnPastUsesAndHistoryData,
  environmentalRestrictionsData,
  hazardousMaterialsData,
  pastViolationsData,
  surroundingLotAnalysisData,
  userDocumentAbstractPhaseOneData,
  userDocumentAbstractPhaseTwoData,
} from "./data/data";
import { useAppSelector } from "../../../../app/hooks";
import EnvironmentalSkeleton from "./EnvironmentalSkeleton";

export default function Environmental() {
  const { isLoading } = useAppSelector((state) => state.demo);

  if (isLoading) return <EnvironmentalSkeleton />;
  return (
    <Accordion title={EnvironmentalInfo.title} id={EnvironmentalInfo.id}>
      <AccordionSectionSpacer>
        {/* Environmental Flags on Past Uses and History */}
        <ReportSection
          title="Environmental Flags on Past Uses and History"
          collapsible
          isInitiallyOpen={false}
        >
          {renderSections(environmentalFlagsOnPastUsesAndHistoryData)}
        </ReportSection>

        {/* Environmental Restrictions */}
        <ReportSection
          title="Environmental Restrictions"
          collapsible
          isInitiallyOpen={false}
        >
          {renderSections(environmentalRestrictionsData)}
        </ReportSection>

        {/* Hazardous Materials */}
        <ReportSection
          title="Hazardous Materials"
          collapsible
          isInitiallyOpen={false}
        >
          {renderSections(hazardousMaterialsData)}
        </ReportSection>

        {/* Past Violations */}
        <ReportSection
          title="Past Violations"
          collapsible
          isInitiallyOpen={false}
        >
          {renderSections(pastViolationsData)}
        </ReportSection>

        {/* Surrounding Lot Analysis */}
        <ReportSection
          title="Surrounding Lot Analysis"
          collapsible
          isInitiallyOpen={false}
        >
          {renderSections(surroundingLotAnalysisData)}
        </ReportSection>

        {/* User Document Abstract - Phase 1 */}
        <ReportSection title="User Document Abstract - Phase 1" collapsible>
          {renderSections(userDocumentAbstractPhaseOneData)}
        </ReportSection>

        {/* User Document Abstract - Phase 2 */}
        <ReportSection
          title="User Document Abstract - Phase 2"
          collapsible
          isInitiallyOpen={false}
        >
          {renderSections(userDocumentAbstractPhaseTwoData)}
        </ReportSection>
      </AccordionSectionSpacer>
    </Accordion>
  );
}
