import React from "react";
import Accordion from "../../../../components/ui/Accordion/Accordion";
import { EntitlementInfo } from "../../helpers/demoSectionInfo";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";
import ReportSection from "../../../../components/ui/ReportSection/ReportSection";
import { renderSections } from "../../helpers/renderers";
import {
  summaryOfPermittedUsesData,
  zoningDesignationHistoryData,
  covenantsAndAgreementsData,
  liensData,
} from "./data/data";
import { useAppSelector } from "../../../../app/hooks";
import EntitlementSkeleton from "./EntitlementSkeleton";

export default function Entitlement() {
  const { isLoading } = useAppSelector((state) => state.demo);

  if (isLoading) return <EntitlementSkeleton />;

  return (
    <Accordion title={EntitlementInfo.title} id={EntitlementInfo.id}>
      <AccordionSectionSpacer>
        {/* Summary of Permitted Uses */}
        <ReportSection title="Summary of Permitted Uses" collapsible>
          {renderSections(summaryOfPermittedUsesData)}
        </ReportSection>

        {/* Zoning Designation History */}
        <ReportSection
          title="Zoning Designation History"
          collapsible
          isInitiallyOpen={false}
        >
          {renderSections(zoningDesignationHistoryData)}
        </ReportSection>

        {/* Covenants and Agreements */}
        <ReportSection
          title="Covenants and Agreements"
          collapsible
          isInitiallyOpen={false}
        >
          {renderSections(covenantsAndAgreementsData)}
        </ReportSection>

        {/* Liens */}
        <ReportSection title="Liens" collapsible isInitiallyOpen={false}>
          {renderSections(liensData)}
        </ReportSection>
      </AccordionSectionSpacer>
    </Accordion>
  );
}
