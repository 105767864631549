export type PreviewCardType = {
  title?: string;
  url?: string;
  description: string;
};

export default function PreviewCard({
  title,
  url,
  description,
}: PreviewCardType) {
  return (
    <div
      className={
        "rounded-lg border-[2px] border-white bg-white px-[20px] py-4 shadow duration-300"
      }
    >
      {title &&
        (url ? (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="link mb-1.5 line-clamp-2"
          >
            {title}
          </a>
        ) : (
          <p className="body-font mb-1.5 line-clamp-2">{title}</p>
        ))}
      <div>
        <p className="desc-font line-clamp-4">{description}</p>
      </div>
    </div>
  );
}
