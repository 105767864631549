import ReportSectionSkeleton from "../../../components/ui/Loaders/ReportSectionSkeleton";
import TextSkeleton from "../../../components/ui/Loaders/TextSkeleton";
import AccordionSkeleton from "../../../components/ui/Loaders/AccordionSkeleton";
import { PlanningAndZoningInfo } from "../../../helpers/constants";
import AccordionSectionSpacer from "../../../components/ui/Accordion/AccordionSectionSpacer";

export default function PlanningAndZoningSkeleton() {
  return (
    <AccordionSkeleton title={PlanningAndZoningInfo.title}>
      <AccordionSectionSpacer>
        <ReportSectionSkeleton title secondaryTitle></ReportSectionSkeleton>

        <ReportSectionSkeleton title secondaryTitle>
          <TextSkeleton title lines={5} />
        </ReportSectionSkeleton>

        <ReportSectionSkeleton title>
          <TextSkeleton instances={2} title lines={4} />
        </ReportSectionSkeleton>

        <ReportSectionSkeleton title secondaryTitle>
          <TextSkeleton title lines={5} />
        </ReportSectionSkeleton>
      </AccordionSectionSpacer>
    </AccordionSkeleton>
  );
}
