import { Map, Marker } from "@vis.gl/react-google-maps";
import { twMerge } from "tailwind-merge";

interface Props {
  showMarker?: boolean;
  defaultZoom?: number;
  coordinates:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
}

const AddressMap = ({
  coordinates,
  defaultZoom = 19,
  showMarker = true,
}: Props) => {
  return (
    <Map
      className={twMerge(
        "h-[350px] w-full overflow-clip rounded-[10px] bg-rf-light-gray shadow-[0px_0px_10px_0px_#00000040] lg:w-[40%] xl:w-[50%]",
      )}
      defaultCenter={coordinates}
      defaultZoom={defaultZoom}
      mapTypeControl={false}
      streetViewControl={false}
      fullscreenControl={false}
      zoomControl={false}
      gestureHandling={"none"}
    >
      {showMarker && <Marker position={coordinates} />}
    </Map>
  );
};

export default AddressMap;
