import { KeyValueContentType } from "../../../../../components/ui/KeyValueSection/KeyValueSection";

export const AddressKVData: KeyValueContentType = [
  {
    ["Site Address"]: [
      "579 E WASHINGTON BLVD",
      "577 E WASHINGTON BLVD",
      "577 1/2 E WASHINGTON BLVD",
      "575 1-5 E WASHINGTON BLVD",
    ],
  },
  { ["ZIP Code"]: "90292" },
  { ["PIN Number"]: "106-5A147 425 9,129.2 (sq ft)" },
  { ["Lot/Parcel Area (Calculated)"]: "9,129.2 (sq ft)" },
  { ["Thomas Brothers Grid"]: "PAGE 671 - GRID J6" },
  { ["Assessor Parcel No"]: "(APN) 4228016009" },
  { ["Tract"]: "TR 20734" },
  { ["Map Reference"]: "M B 558-28" },
  { ["Block"]: "None" },
  { ["Arb (Lot Cut Reference)"]: "None" },
  { ["Lot"]: "2" },
  { ["Map Sheet"]: "106-5A147" },
];

export const JurisdictionalKVData: KeyValueContentType = [
  { ["Community Plan Area"]: "Venice" },
  { ["Area Planning Commission"]: "West Los Angeles" },
  { ["Neighborhood Council"]: "Venice" },
  { ["Council District"]: "CD 11 - Traci Park" },
  { ["Census Tract #"]: "2739.02" },
  { ["LADBS District Office"]: "West Los Angeles" },
];
