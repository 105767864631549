import { twMerge } from "tailwind-merge";
import { capitalizeUnderscoreString } from "../../../helpers/stringUtils";
import React from "react";

export type KeyValueContentType = Array<{ [key: string]: string | string[] }>;

type Props = {
  content: KeyValueContentType;
  className?: string;
  keyHeader?: string;
  valueHeader?: string;
  keysBold?: boolean;
  compact?: boolean;
};

export default function KeyValueSection({
  content,
  className,
  keyHeader,
  valueHeader,
  keysBold,
  compact,
}: Props) {
  return (
    <div className={twMerge("w-full", className)}>
      <div
        className={twMerge("grid gap-x-3 gap-y-2.5", compact && "gap-y-0")}
        style={{ gridTemplateColumns: "fit-content(50%) 1fr" }}
      >
        {/* Headers */}
        {keyHeader && (
          <>
            <p
              className={twMerge("body-font font-bold", compact && "desc-font")}
            >
              {keyHeader}
            </p>
            <p
              className={twMerge("body-font font-bold", compact && "desc-font")}
            >
              {valueHeader}
            </p>
          </>
        )}
        {/* Content Rows */}
        {content.map((item, index) => {
          const keys = Object.keys(item);
          let values: string[][];

          if (Array.isArray(Object.values(item))) {
            values = Object.values(item) as string[][];
          } else {
            values = [Object.values(item) as string[]];
          }

          return keys.map((key, idx) => (
            <React.Fragment key={`${index}-${key}`}>
              {/* Key */}
              <p
                className={twMerge(
                  `body-font line-clamp-1 break-all sm:min-w-[274px]`,
                  keysBold && "font-bold",
                  compact && "desc-font",
                )}
              >
                {capitalizeUnderscoreString(key)}
              </p>

              {/* Value */}
              <div className="thin-scrollbar max-h-[129px] overflow-y-auto sm:max-h-[136px] lg:max-h-[144px]">
                {Array.isArray(values[idx]) ? (
                  values[idx].map((val, i) => (
                    <p
                      className={twMerge(
                        "body-font line-clamp-3 break-words",
                        values[idx].length !== i + 1 && "mb-1.5",
                        compact && "desc-font",
                      )}
                      key={i}
                    >
                      {val}
                    </p>
                  ))
                ) : (
                  <p
                    className={twMerge(
                      "body-font line-clamp-3 break-words",
                      compact && "desc-font",
                    )}
                  >
                    {values[idx]}
                  </p>
                )}
              </div>
            </React.Fragment>
          ));
        })}
      </div>
    </div>
  );
}
