import { twMerge } from "tailwind-merge";
import { ChevronDown12Filled } from "@fluentui/react-icons";
import useScrollVisibility from "./useScrollVisibility";
import { isAndroid } from "../../helpers/deviceDetection";
import FixedPositionWrapper from "../ui/FixedPositionWrapper/FixedPositionWrapper";
import Button from "../ui/Buttons/Button";

export default function BackToTop() {
  const showButton = useScrollVisibility(500);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <FixedPositionWrapper placement="bottom-left">
      <Button
        variant="secondary"
        onClick={scrollToTop}
        className={twMerge(
          showButton ? "block" : "hidden",
          isAndroid() ? "hidden sm:block" : "",
        )}
        icon={{
          icon: <ChevronDown12Filled />,
          className: "rotate-180",
          rounded: true,
        }}
      />
    </FixedPositionWrapper>
  );
}
