import React from "react";
import AccordionSkeleton from "../../../../components/ui/Loaders/AccordionSkeleton";
import TextSkeleton from "../../../../components/ui/Loaders/TextSkeleton";
import ReportSectionSkeleton from "../../../../components/ui/Loaders/ReportSectionSkeleton";
import { PlanningAndZoningInfo } from "../../helpers/demoSectionInfo";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";

export default function PlanningAndZoningSkeleton() {
  return (
    <AccordionSkeleton title={PlanningAndZoningInfo.title}>
      <AccordionSectionSpacer>
        <ReportSectionSkeleton title secondaryTitle>
          <TextSkeleton lines={14} columns={2} lineWidth={100} />
        </ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
        <ReportSectionSkeleton title secondaryTitle>
          <TextSkeleton lines={5} columns={1} lineWidth={100} />
        </ReportSectionSkeleton>
        <ReportSectionSkeleton title>
          <TextSkeleton
            title
            lines={4}
            columns={1}
            instances={4}
            lineWidth={100}
          />
        </ReportSectionSkeleton>
        <ReportSectionSkeleton title></ReportSectionSkeleton>
      </AccordionSectionSpacer>
    </AccordionSkeleton>
  );
}
