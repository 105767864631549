/**
 * Replaces underscores with spaces in a string.
 * Example: "zoning_description" -> "zoning description"
 */
export function replaceUnderscoresWithSpaces(key: string): string {
  return key.replace(/_/g, " ");
}

/**
 * Capitalizes the first letter of each word in a string.
 * Example: "zoning description" -> "Zoning Description"
 */
export function capitalizeWords(input: string): string {
  return input
    .split(" ") // Split the string by spaces
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back with spaces
}

/**
 * Capitalizes only the first word in a string.
 * Example: "zoning description" -> "Zoning description"
 */
export function capitalizeFirstWord(input: string): string {
  if (!input) return input; // Handle empty string case
  return input.charAt(0).toUpperCase() + input.slice(1);
}

/**
 * Formats a key by replacing underscores with spaces and capitalizing the first letter of each word.
 * Example: "zoning_description" -> "Zoning Description"
 */
export function capitalizeUnderscoreString(key: string): string {
  return capitalizeWords(replaceUnderscoresWithSpaces(key));
}
