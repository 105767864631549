import React from "react";

export default function Footer() {
  return (
    <footer
      className={"flex h-10 flex-col items-center justify-center bg-rf-warm-gray"}
    >
      <a className="body-font  text-white" href={"/tos"}>Terms and Conditions</a>
    </footer>
  );
}
