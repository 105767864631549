import React from "react";
import AccordionSkeleton from "../../../components/ui/Loaders/AccordionSkeleton";
import ListDetailsSkeleton from "../../../components/ui/Loaders/ListDetailsSkeleton";
import { RelevantMotionsInfo } from "../../../helpers/constants";

export default function RelevantMotionsSkeleton() {
  return (
    <AccordionSkeleton title={RelevantMotionsInfo.title}>
      <ListDetailsSkeleton />
    </AccordionSkeleton>
  );
}
