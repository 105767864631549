type HtmlParsedAnswer = {
    answerElements: JSX.Element[];
    citations: string[];
};


export function parseAnswerToHtml(answer: string, isStreaming: boolean, onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {
    try {
        let answerJson = JSON.parse(answer);
        answer = JSON.stringify(answerJson, null, 2);
    } catch (error) {

    }

    const citations: string[] = [];

    // trim any whitespace from the end of the answer after removing follow-up questions
    let parsedAnswer = answer.trim();

    const parts = parsedAnswer.split(/(\[\[[^\]]+\]\]|\n)/g);

    const fragments: JSX.Element[] = parts.map((part, index) => {
        if (part === '\n') {
            return <br key={`br-${index}`} />;
        }
        if (index % 2 === 0) {
            return <span>{part}</span>;
        } else {
            let citationIndex: number;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }

            const path = part;


            return (
                <a className="supContainer" title={part} onClick={() => onCitationClicked(path)}>
                    <sup>{citationIndex}</sup>
                </a>
            );
        }
    });

    return {
        answerElements: fragments,
        citations
    };
}
