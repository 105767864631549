import React, {useMemo, useRef} from "react";
import Accordion from "../../components/ui/Accordion/Accordion";
import ReportSection from "../../components/ui/ReportSection/ReportSection";
import {ParsedAnnotation} from "../../components/Answer";
import {twMerge} from "tailwind-merge";
import AnswerToUserQuestionSkeleton from "./components/AnswerToUserQuestionSkeleton";
import { useAppSelector } from "../../app/hooks";
import AccordionSectionSpacer from "../../components/ui/Accordion/AccordionSectionSpacer";
import DocumentRenderer, {DocumentRendererHandle} from "./components/DocumentRenderer/DocumentRenderer";
import {AnswerToUserQuestionInfo} from "../../helpers/constants";
import {answerToElements} from "./util/AnswerToElements";
import {EndpointOutputs} from "../../global/types";

interface AnswerToUserQuestionProps {
  completeAnswer?: {
    endpointOutputs: EndpointOutputs,
    isLoading: boolean,
  },
  title?: string,
  query?: string
}

export default function AnswerToUserQuestion({completeAnswer, title, query}: AnswerToUserQuestionProps) {
  const {
    endpointOutputs: {userQueryAnswer} = {},
    isLoading,
  } = completeAnswer ?? useAppSelector((state) => state.addressSearch);

  const answer = userQueryAnswer?.[0]?.[1];

  const messageContent = answer?.choices?.[0]?.message?.content ?? "";

  const DocumentRendererRef = useRef<DocumentRendererHandle>(null);

  const navigateToCitation = (annotation: string): void => {
    const parsed = new ParsedAnnotation(annotation);
    if (parsed && DocumentRendererRef.current) {
      DocumentRendererRef.current.gotoAnnotation(parsed.getFirstAnnotation());
    }
  };

  // Calculate parsedAnswerElements, sources, and additionalSources using useMemo
  const {parsedAnswerElements, sources, additionalSources} = useMemo(() => {
    return answerToElements(isLoading, messageContent, navigateToCitation);
  }, [isLoading, messageContent, navigateToCitation]); // Re-compute when isLoading or messageContent changes

  // Remove the unnecessary call to parseAnswerToHtml here

  if (isLoading) return <AnswerToUserQuestionSkeleton/>;

  if (!userQueryAnswer?.length && !isLoading) {
    return <></>;
  }

  return (
    <Accordion
      id={AnswerToUserQuestionInfo.id}
      title={title || AnswerToUserQuestionInfo.title}
    >
      <AccordionSectionSpacer>
        {/* Interpretation */}
        <ReportSection collapsible title={"Interpretation"}>
          <div>
            {parsedAnswerElements.answerElements.map((element, index) => (
              <span className="desc-font" key={index}>
                {element}
              </span>
            ))}
          </div>
          <p className={twMerge("desc-font mt-3 text-center")}>
            The interpretation response are generalized. Please refer to the
            "Document Search Results" section for specific answers
          </p>
        </ReportSection>

        {/* Document search results */}
        {!!sources.length && (
          <DocumentRenderer
            ref={DocumentRendererRef}
            citedSources={sources}
            additionalSources={additionalSources}
            collapsible={true}
            limitMaxHeight={false}
          />
        )}
      </AccordionSectionSpacer>
    </Accordion>
  );
}
