import readNDJSONStream from "ndjson-readablestream";
import {
  addressSearchApi,
  ChatAppRequest,
  ChatAppResponse,
  ChatAppResponseOrError,
  getTimeline,
  GPT4VInput,
  PermitTimelineRequest,
  PermitTimelineResponse,
  ResponseMessage,
  RetrievalMode,
  siteInfoApi,
  SiteInfoRequest,
  SiteInfoResponse,
  VectorFieldOptions,
} from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setEndpointOutputs,
  setActiveCitation,
  setActiveAnalysisPanelTab,
} from "../addressSearchSectionSlice";
import { openModal } from "../../modalManager/modalSlice";
import { useGetSearchConfigQuery } from "../addressSearchSectionApiSlice";
import { resetAddressSearchState } from "../addressSearchSectionSlice";

type SearchConfigType = {
  promptTemplate: string;
  temperature: number;
  minimumRerankerScore: number;
  minimumSearchScore: number;
  retrieveCount: number;
  shouldStream: boolean;
  useSemanticCaptions: boolean;
  excludeCategory: string;
  useSuggestFollowupQuestions: boolean;
  vectorFieldList: VectorFieldOptions[];
  useOidSecurityFilter: boolean;
  useGroupsSecurityFilter: boolean;
  gpt4vInput: GPT4VInput;
  useGPT4V: boolean;
};

const searchConfig: SearchConfigType = {
  promptTemplate: "",
  temperature: 0.3,
  minimumRerankerScore: 0,
  minimumSearchScore: 0,
  retrieveCount: 3,
  shouldStream: false,
  useSemanticCaptions: false,
  excludeCategory: "",
  useSuggestFollowupQuestions: false,
  vectorFieldList: [VectorFieldOptions.Embedding],
  useOidSecurityFilter: false,
  useGroupsSecurityFilter: false,
  gpt4vInput: GPT4VInput.TextAndImages,
  useGPT4V: false,
};

type Props = {
  userPrompt: string | undefined;
  permitSubType: string | undefined;
  programType: string | undefined;
  miscTypeSelected: Set<string>;
  zoningType: string | undefined;
  coordinates:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
  lastLocationRef: React.MutableRefObject<string>;
  error: unknown;
  setError: React.Dispatch<unknown>;
  setIsStreaming: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function useSubmitSearchQuery({
  userPrompt,
  permitSubType,
  programType,
  miscTypeSelected,
  coordinates,
  lastLocationRef,
  error,
  zoningType,
  setError,
  setIsStreaming,
}: Props) {
  const modules = undefined;

  const dispatch = useAppDispatch();

  const { data: searchData } = useGetSearchConfigQuery();

  const { endpointOutputs } = useAppSelector((state) => state.addressSearch);

  const useSemanticRanker = searchData
    ? searchData.showSemanticRankerOption
    : true;
  const retrievalMode =
    searchData && searchData.showVectorOption
      ? RetrievalMode.Text
      : RetrievalMode.Hybrid;

  const openMotionModal = () => {
    // temporary workaround
    setTimeout(() => {
      dispatch(resetAddressSearchState());
    }, 50);

    dispatch(
      openModal({
        modalType: "SearchError",
        modalTitle: "Couldn't generate a report",
      }),
    );
  };

  const makeApiRequest = async (
    [parkingQuery, signageQuery, setbackQuery]: string[],
    location: string,
    coords?: number[],
  ): Promise<void> => {
    dispatch(setEndpointOutputs({ parkingAnswer: [] }));

    lastLocationRef.current = location;

    error && setError(undefined);

    dispatch(setActiveCitation(undefined));
    dispatch(setActiveAnalysisPanelTab(undefined));

    const messages: ResponseMessage[] = endpointOutputs.parkingAnswer.flatMap(
      (a) => [
        { content: a[0], role: "user" },
        { content: a[1].choices[0].message.content, role: "assistant" },
      ],
    );

    const request: ChatAppRequest = {
      messages: [...messages, { content: parkingQuery, role: "user" }],
      location: location,
      stream: searchConfig.shouldStream,
      user_query: parkingQuery,
      change_zone: zoningType,
      additional_categories: [
        ...Array.from(miscTypeSelected),
        ...(programType ? [programType] : []),
      ],
      context: {
        overrides: {
          prompt_template:
            searchConfig.promptTemplate.length === 0
              ? undefined
              : searchConfig.promptTemplate,
          exclude_category:
            searchConfig.excludeCategory.length === 0
              ? undefined
              : searchConfig.excludeCategory,
          top: searchConfig.retrieveCount,
          temperature: searchConfig.temperature,
          minimum_reranker_score: searchConfig.minimumRerankerScore,
          minimum_search_score: searchConfig.minimumSearchScore,
          retrieval_mode: retrievalMode,
          semantic_ranker: useSemanticRanker,
          semantic_captions: searchConfig.useSemanticCaptions,
          suggest_followup_questions: searchConfig.useSuggestFollowupQuestions,
          use_oid_security_filter: searchConfig.useOidSecurityFilter,
          use_groups_security_filter: searchConfig.useGroupsSecurityFilter,
          vector_fields: searchConfig.vectorFieldList,
          use_gpt4v: searchConfig.useGPT4V,
          gpt4v_input: searchConfig.gpt4vInput,
          address_coordinates: coords,
        },
      },
      // ChatAppProtocol: Client must pass on any session state received from the server
      session_state: endpointOutputs.parkingAnswer.length
        ? endpointOutputs.parkingAnswer[
            endpointOutputs.parkingAnswer.length - 1
          ][1].choices[0].session_state
        : null,
    };

    const searches: Promise<void>[] = [];

    const getSiteInfoRequest: SiteInfoRequest = {
      address: location,
      coordinates: coords,
    };
    dispatch(setEndpointOutputs({ siteInfoResponse: undefined }));

    if (!modules) {
      searches.push(
        siteInfoApi(getSiteInfoRequest).then(async (response) => {
          if (response.status === 404) {
            openMotionModal();
            return;
          }
          if (!response.body) {
            throw Error("No response body");
          }

          const parsedResponse: SiteInfoResponse = await response.json();
          dispatch(setEndpointOutputs({ siteInfoResponse: parsedResponse }));
        }),
      );
    }

    let openEndedRequest = { ...request };
    openEndedRequest.user_query = userPrompt;

    dispatch(
      setEndpointOutputs({
        userQueryAnswer: [],
      }),
    );

    if (!modules && !!userPrompt) {
      dispatch(
        setEndpointOutputs({
          userQueryAnswer: [],
        }),
      );
      searches.push(
        addressSearchApi(openEndedRequest).then(async (response) => {
          if (!response.body) {
            throw Error("No response body");
          }
          if (searchConfig.shouldStream) {
            const parsedResponse: ChatAppResponse = await handleAsyncRequest(
              userPrompt,
              endpointOutputs.userQueryAnswer,
              response.body,
            );
            dispatch(
              setEndpointOutputs({
                userQueryAnswer: [[userPrompt, parsedResponse]],
              }),
            );
          } else {
            const parsedResponse: ChatAppResponseOrError =
              await response.json();
            if (response.status === 404) {
              openMotionModal();
              return;
            } else if (response.status > 299 || !response.ok) {
              throw Error(parsedResponse.error || "Unknown error");
            } else {
              dispatch(
                setEndpointOutputs({
                  userQueryAnswer: [
                    [userPrompt, parsedResponse as ChatAppResponse],
                  ],
                }),
              );
            }
          }
        }),
      );
    }

    const getTimelineRequest: PermitTimelineRequest = {
      address: location,
      coordinates: [coordinates?.lat ?? 0, coordinates?.lng ?? 0],
      permitSubType: permitSubType,
      change_zone: zoningType
    };

    dispatch(
      setEndpointOutputs({
        permitTimeline: undefined,
      }),
    );

    if (!modules) {
      searches.push(
        getTimeline(getTimelineRequest).then(async function (response) {
          if (response.status === 404) {
            return;
          }
          if (modules) {
            return;
          }
          if (!response.body) {
            throw Error("No response body");
          }
          const parsedResponse: PermitTimelineResponse = await response.json();

          dispatch(
            setEndpointOutputs({
              permitTimeline: parsedResponse,
            }),
          );
        }),
      );
    }
    await Promise.all(
      searches.map((promise) => promise.catch((error) => ({ error }))),
    );
  };

  const handleAsyncRequest = async (
    question: string,
    answers: [string, ChatAppResponse][],
    responseBody: ReadableStream<any>,
  ) => {
    let answer: string = "";
    let askResponse: ChatAppResponse = {} as ChatAppResponse;

    const updateState = (newContent: string) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          answer += newContent;
          const latestResponse: ChatAppResponse = {
            ...askResponse,
            choices: [
              {
                ...askResponse.choices[0],
                message: {
                  content: answer,
                  role: askResponse.choices[0].message.role,
                },
              },
            ],
          };

          dispatch(
            setEndpointOutputs({
              streamedAnswers: [...answers, [question, latestResponse]],
            }),
          );

          resolve(null);
        }, 33);
      });
    };
    try {
      setIsStreaming(true);
      for await (const event of readNDJSONStream(responseBody)) {
        if (
          event["choices"] &&
          event["choices"][0]["context"] &&
          event["choices"][0]["context"]["data_points"]
        ) {
          event["choices"][0]["message"] = event["choices"][0]["delta"];
          askResponse = event as ChatAppResponse;
        } else if (
          event["choices"] &&
          event["choices"][0]["delta"]["content"]
        ) {
          await updateState(event["choices"][0]["delta"]["content"]);
        } else if (event["choices"] && event["choices"][0]["context"]) {
          // Update context with new keys from latest event
          askResponse.choices[0].context = {
            ...askResponse.choices[0].context,
            ...event["choices"][0]["context"],
          };
        } else if (event["error"]) {
          throw Error(event["error"]);
        }
      }
    } finally {
      setIsStreaming(false);
    }
    const fullResponse: ChatAppResponse = {
      ...askResponse,
      choices: [
        {
          ...askResponse.choices[0],
          message: {
            content: answer,
            role: askResponse.choices[0].message.role,
          },
        },
      ],
    };
    return fullResponse;
  };

  return [makeApiRequest] as const;
}
