import React, {useState, useEffect, useRef, FormEvent} from 'react';
import { useSearchParams } from 'react-router-dom';
import {Dropdown, IconButton, Modal, PrimaryButton, TextField} from "@fluentui/react";
import { ParsedAnnotation } from "../../../components/Answer";
import { DocumentCRUD } from "./documentCrud"
import {Document} from "../../../api";

import DocumentRenderer, {
    DocumentRendererHandle
} from "../../../features/AnswerToUserQuestion/components/DocumentRenderer/DocumentRenderer";
import {IDropdownOption} from "@fluentui/react/lib/Dropdown";
import {Button} from "flowbite-react";


const DocumentAdmin: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [sources, setSources] = useState<ParsedAnnotation[]>([]);
    const [filteredSources, setFilteredSources] = useState<ParsedAnnotation[]>([]);
    const [selectedSection, setSelectedSection] = useState<string | undefined>(undefined);
    const [availableSections, setAvailableSections] = useState<string[]>([]);
    const [textContent, setTextContent] = useState<string[]>([]);
    const [interpretationNotes, setInterpretationNotes] = useState<string>('');

    let [documentName, setDocumentName] = useState('');
    let [documentId, setDocumentId] = useState('');

    const crud = new DocumentCRUD();
    const DocumentRendererRef = useRef<DocumentRendererHandle>(null);

    useEffect(() => {
        const sourcesFromParams = searchParams.get('sources');
        const searchDocument = searchParams.get('document');

        if (sourcesFromParams) {
            try {
                const parsedSources: ParsedAnnotation[] = JSON.parse(sourcesFromParams);
                setSources(parsedSources);
                } catch (error) {
                    console.error('Error parsing annotation sources from URL:', error);
                }
            }

        if (searchDocument) {
            const fetchData = async () => {
                try {
                    let annotations = await crud.getAnnotationsFromDocument(searchDocument);
                    const document = await crud.getDocument(searchDocument);
                    setDocumentName(document?.name ?? '');
                    setDocumentId(document?.id);
                    const fetchedSources: ParsedAnnotation[] = annotations.map(annotation => {
                        return new ParsedAnnotation(annotation.id, annotation.section, document?.id)
                    });
                    fetchedSources.sort(ParsedAnnotation.compare)
                    const uniqueSections = fetchedSources.filter(x => !!x).reduce((acc, current) => {
                        if (!acc.includes(current.section!)) {
                            acc.push(current.section!);
                        }
                        return acc;
                    }, [] as string[]);
                    return { fetchedSources, uniqueSections };
                } catch (error) {
                    console.error('Error fetching data:', error);
                    throw error;
                }
            }
            fetchData().then((data: { fetchedSources: ParsedAnnotation[], uniqueSections: string[] }) => {
                data.fetchedSources.sort();
                setSources(data.fetchedSources);
                setAvailableSections(data?.uniqueSections ?? []);
                updateFilteredSources(data.fetchedSources, selectedSection);
            });
        }
    }, [searchParams, selectedSection]);



    const updateFilteredSources = (sourcesToFilter: ParsedAnnotation[], section: string | undefined) => {
        const filtered = section
            ? sourcesToFilter.filter(source => source.section === section)
            : sourcesToFilter;
        setFilteredSources(filtered);
};

    useEffect(() => {
        updateFilteredSources(sources, selectedSection);
    }, [selectedSection, sources]);

    useEffect(() => {
        if (DocumentRendererRef.current && filteredSources.length > 0) {
            DocumentRendererRef.current.gotoAnnotation(filteredSources[0].getFirstAnnotation());
        }
    }, [filteredSources]);

    useEffect(() => {
        if(!!selectedSection && filteredSources.length > 0) {
            (crud.getAnnotationDetails(filteredSources.map(src => src.getAllAnnotations()).flat()).then(async function (details) {
                const annotationDetails = (await details.json());
                setTextContent(annotationDetails.map((x: any) => (x.content) ?? []));
                setInterpretationNotes(annotationDetails[0].interpretation_note ?? '');
            }))
        }
    }, [filteredSources]);


    function saveInterpretationNotes() {
        crud.setInterpretationNote(filteredSources.map(src => src.getAllAnnotations()).flat(), interpretationNotes);
    }


    function handleSectionChange(event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined): void {
        setSelectedSection(option?.text);
    }
    function saveDocumentName(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!!documentId) {
            return crud.updateDocument(documentId, {name: documentName});
        }
    }

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingSectionName, setEditingSectionName] = useState('');

    function openEditModal() {
        setEditingSectionName(selectedSection || '');
        setIsEditModalOpen(true);
    }

    function closeEditModal() {
        setIsEditModalOpen(false);
    }

    async function saveEditedSectionName() {
        let ids = filteredSources.map(annotation => annotation.getAllAnnotations()).flat()
        let prevSectionName = filteredSources[0].section;
        let resp = await crud.setSectionName(ids, editingSectionName)
        if (resp.status === 200) {
            for (let i=0; i<availableSections.length;++i){
                if (availableSections[i] == prevSectionName) {
                    availableSections[i] = editingSectionName;
                    setSelectedSection(editingSectionName);
                }
            }
        }
        closeEditModal();
    }

    return (
        <div>
            <form onSubmit={saveDocumentName}>
                <div className="text-lg flex items-center gap-2 justify-center">
                    <label htmlFor="documentName">Document Name</label>
                    <input
                      id="documentName"
                      onChange={(e) => setDocumentName(e.target.value)}
                      value={documentName}
                      style={{width: '50ch'}}
                    />
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </div>
                <div className="text-lg flex items-center gap-2 justify-center">
                    <label htmlFor="documentName">Section</label>
                    <Dropdown
                        options={[
                            { key: 'blank', text: '' },
                            ...availableSections.map(section => ({ key: section, text: section }))
                        ]}
                        selectedKey={selectedSection || 'blank'}
                        onChange={handleSectionChange}
                        placeholder="Select a section"
                        styles={{ dropdown: { width: 300 } }}
                    />
                    <IconButton
                      iconProps={{ iconName: 'Edit' }}
                      onClick={openEditModal}
                      disabled={!selectedSection}
                    />
                </div>
            </form>
            <hr />
            {filteredSources?.length > 0 && (
                <DocumentRenderer
                    key={documentName}
                    ref={DocumentRendererRef}
                    citedSources={filteredSources}
                    additionalSources={[]}
                    highlightActiveSectionMode={true}
                />
            )}
            <form className="flex flex-col items-center gap-4 mt-4 w-full m-2">
                <div className="text-lg w-full">
                    <label htmlFor="sectionNotes" className="block mb-2 font-bold text-lg">Text Content</label>
                    <div className="text-content">
                        {textContent.map((paragraph, index) => (
                          <div>
                              <p className={"font-bold my-1"}>{filteredSources[index].getAllAnnotations()}</p>
                              <p key={index} dangerouslySetInnerHTML={{__html: paragraph}}/>
                          </div>
                        ))}
                    </div>

                    {/*Hack because admin page. */}
                    <style>{`
                      .text-content table {
                        border-collapse: collapse;
                        border: 1px solid black;
                      }
                      .text-content td, .text-content th {
                        border: 1px solid black;
                        padding: 8px;
                      }
                    `}</style>
                    <label htmlFor="sectionNotes" className="block mb-2 font-bold text-lg my-2">Raw Data</label>
                    {textContent.map(content =>
                      <textarea
                        id="sectionNotes"
                        value={content}
                        className="w-full h-32 p-2 border rounded"
                      />)}
                    <label htmlFor="sectionNotes" className="block mb-2 font-bold my-2">Section Notes</label>
                    <textarea
                      id="sectionNotes"
                      placeholder="Enter notes to help the AI interpret this section"
                      value={interpretationNotes}
                      onChange={(event) => setInterpretationNotes(event.target.value)}
                      disabled={!selectedSection}
                      className="w-full h-32 p-2 border rounded"
                    />
                </div>
                <Button
                  onClick={saveInterpretationNotes}
                  disabled={!selectedSection}
                  className="mt-2"
                >
                  Save Section Notes
                </Button>
            </form>

            <Modal
              isOpen={isEditModalOpen}
              onDismiss={closeEditModal}
              isBlocking={false}
              containerClassName="w-1/3 p-4"
            >
                <h2 className="text-xl mb-4">Edit Section Name</h2>
                <TextField
                  label="Section Name"
                  value={editingSectionName}
                  onChange={(_, newValue) => setEditingSectionName(newValue || '')}
                />
                <div className="mt-4 flex justify-end gap-2">
                    <PrimaryButton onClick={saveEditedSectionName}>Save</PrimaryButton>
                    <Button onClick={closeEditModal}>Cancel</Button>
                </div>
            </Modal>
        </div>

    );
};
export default DocumentAdmin;
